.contentC {
   padding: 1rem 2rem;
   max-height: 100%;
   width: 40rem;
   overflow-y: auto;
   > :not(:last-child) {
      margin-bottom: 16px;
   }
   .titleC {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
         font-weight: 700;
         font-size: 30px;
      }
      .close {
         border: 1px solid var(--text-1);
         padding: 0.2rem 0.4rem;
         cursor: pointer;
      }
   }
   .headerInfoC {
      .address {
         font-weight: 700px;
         font-size: 20px;
         text-decoration: underline;
      }
   }
   .controlsC {
   }
   .streetView {
      max-width: 100%;
   }
}
