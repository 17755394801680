.contentC {
   background-color: var(--bgc-1);
   height: 100%;
   overflow-y: auto;
   padding: 1rem;
   display: flex;
   flex-direction: column;
   .headerC {
      display: flex;
      gap: 8px;
      align-items: center;
   }
   .bodyC {
      flex-grow: 1;
      overflow-y: auto;
      background-color: var(--body-bgc-1);
      width: 100%;
      border-radius: 10px;
      padding: 8px;
      .selectedC,
      .selectionsC {
         padding: 8px;
         background-color: var(--bgc-1);
         border-radius: 6px;
      }
      .selectedC {
         margin-bottom: 1rem;
         display: flex;
         flex-wrap: wrap;
         gap: 4px;
         max-height: 90px;
         overflow-y: auto;
         > button {
            padding: 2px 4px;
            border: 1px solid var(--text-1);
            border-radius: 10px;
            display: flex;
            align-items: center;
            gap: 4px;
            > span:last-child {
               transform: rotate(45deg);
            }
            &:hover {
               background-color: var(--text-1);
               > span {
                  color: var(--bgc-1);
               }
            }
         }
      }
      .selectionsC {
         .selectionsHeader {
            display: flex;
            align-items: center;
            gap: 8px;
            padding-bottom: 8px;
         }
      }
   }
}

.Groups {
   background-color: var(--body-bgc-1);
   padding: 8px;
   display: flex;
   gap: 0.4rem;
   width: fit-content;
   max-width: 100%;
   overflow-x: auto;

   .Group {
      max-width: 100%;
      border-radius: 14px;
      background-color: var(--bgc-1);
      padding: 4px 8px;
      width: fit-content;
      .GroupTitle {
         display: flex;
         align-items: center;
         font-size: 14px;
         font-weight: 700;
         white-space: nowrap;
      }
      .typeGroup {
         display: flex;
         flex-direction: column;
         height: 500px;
         overflow-y: auto;
         overflow-x: hidden;
         gap: 8px;
         .fieldC {
            display: flex;
            align-items: center;
            >span {
               white-space: nowrap;
            }
         }
      }
   }
}
