.contentC {
   // width: 26rem;
   .children {
      .title {
         font-size: 18px;
         white-space: nowrap;
         margin-bottom: 10px;
      }
      .childC {
         display:flex;
         flex-direction: column;
         gap: 10px;
         > * {
            width: 14rem;
         }
      }
   }
   .tableC {
      overflow-x: overlay;
      .title {
         font-size: 18px;
         white-space: nowrap;
         padding: 20px 20px 14px 20px;
      }
   }
}
