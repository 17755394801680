.contentC {
   .headerC {
      padding: 1rem 2rem 0 2rem;
      background-color: var(--bgc-1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      .headerL {
         display: flex;
         align-items: center;
         gap: 8px;
      }
      .headerR {
         display: flex;
         gap: 10px;
         align-items: center;
      }
   }

   .stagesC {
      padding: 0 2rem 1rem 2rem;
      background-color: var(--bgc-1);
      display: flex;
      gap: 8px;
   }
}
