.contentC {
   .content {
      padding: 1rem;
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: space-between;
      background-color: var(--bgc-1);
      .verSelect {
         display: flex;
         gap: 1rem;
         align-items: center;
      }
      .verControls {
         .controlsC {
            display: flex;
            align-items: center;
            gap: 1rem;
            .controls {
               display: flex;
            }
         }
      }
   }
}
