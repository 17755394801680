.contentC {
   .progressC {
      position: fixed;
      bottom: 1rem;
      right: 1rem;
      display: flex;
      gap: 1rem;
      align-items: flex-end;
   }
   .controlsC {
      background-color: var(--bgc-1);
      display: flex;
      padding: 1rem;
      // justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px;
      .csvUploadedC {
         font-size: 12px;
      }
   }
}
