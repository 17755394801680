.contentC {
   background: #fff;
   padding: 1rem 2rem;
   border-radius: 10px;
   width: 30rem;
   .titleC,
   .creationC,
   .controls {
      display: flex;
      align-items: center;
      margin-bottom: 0.6rem;
      justify-content: space-between;
   }
   .infoC {
      .preview {
         max-height: 16rem;
         overflow: overlay;
         overscroll-behavior: contain;
      }
   }
}
