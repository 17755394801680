.contentC {
   width: 40rem;
   padding: 0 1rem;
   .link {
      text-decoration: underline;
      display: block;
   }
   .preview {
      width: 500px;
      height: 500px;
   }
}