.contentC {
   position: relative;
   background-color: red;
   border: 4px solid black;
   height: 1.2rem;
   width: 1.2rem;
   cursor: pointer;
   .hoverC {
      background-color: var(--bgc-1);
      padding: 0.4rem 0.8rem;
      border-radius: 10px;
      position: absolute;
      top: -4px;
      left: 50%;
      transform: translate(-50%, -100%);
      display: none;
      gap: 0.4rem;
      img {
         height: 160px;
         width: 160px;
      }
      .infoC {
         width: 20rem;
         .address {
            font-weight: 700;
            font-size: 18px;
         }
      }
   }
   &:hover {
      z-index: 9;
      background-color: var(--main-1);
      .hoverC {
         z-index: 9;
         display: flex;
      }
   }
}
