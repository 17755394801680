.previewSettingsC {
   background-color: var(--background-0);
   width: min(100%, 500px);
   padding-bottom: 1rem;
   .headerC {
      padding: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--background-1);
      margin-bottom: 10px;
   }
   .bodyC {
      width: 80%;
      margin: auto;
      display: flex;
      gap: 1rem;
      align-items: center;
   }
}
.contentC {
   background-color: var(--body-bgc-1);
   .headerC {
      display: flex;
      justify-content: space-between;
      background-color: var(--bgc-1);
      padding: 1rem 1rem 0 1rem;
      .headerL {
         display: flex;
         gap: 1rem;
         align-items: center;
      }
      .headerR {
         display: flex;
         align-items: flex-end;
         gap: 1rem;
      }
   }
   .tabButtonsC {
      background-color: var(--bgc-1);
      padding-inline: 0.5rem;
   }
}
