.contentC {
   position: relative;
   border-top: 1px solid var(--text-1-o);
   > :not(:last-child) {
      margin-bottom: 1rem;
   }
   .controls {
      display: flex;
      gap: 1rem;
   }
   .error {
      cursor: pointer;
      padding: 1rem;
      background-color: var(--main-3);
      border: 2px solid var(--main-4);
      border-radius: 10px;
      width: fit-content;
      color: red;
   }
   .inputs {
      display: flex;
      gap: 0.4rem;
      flex-wrap: wrap;
   }
}
