.contentC {
   width: fit-content;
   height: fit-content;
   outline: 2px dotted;
   border-radius: 10px;
   white-space: nowrap;
   padding: 0.2rem 0.4rem;
   outline-color: var(--main-2);
   background-color: var(--main-2);
}
.green {
   outline-color: var(--main-1);
   background-color: var(--main-1);
}
.orange {
   outline-color: var(--main-3);
   background-color: var(--main-3);
}
