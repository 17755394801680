.contentC {
   background-color: var(--body-bgc-1);
   border-radius: 20px;
   width: min(96%, 600px);
   .headerC {
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--bgc-1);
      border-radius: 20px 20px 0 0;
      .headerL {
         text-transform: capitalize;
      }
      .headerR {
      }
   }
   .bodyC {
      padding: 1rem;
      .inputsC {
         display: flex;
         flex-direction: column;
         gap: 8px;
         margin-bottom: 1rem;
         .inputRow {
            flex-grow: 1;
            display: flex;
            gap: 12px;
         }
      }
      .configC {
         padding: 12px;
         padding-bottom: 12rem;
         height: 400px;
         overflow-y: scroll;
         margin-bottom: 1rem;
         background-color: var(--bgc-2);
         border: 1px solid var(--text-1-o);
         .configHeader {
            margin-bottom: 8px;
            display: flex;
            justify-content: space-between;
            > :last-child {
               width: 120px;
            }
         }
         .configBody {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-bottom: 1rem;
            .configBodyRow {
               display: flex;
               gap: 8px;
               align-items: center;
            }
            .fxTextarea {
               outline: 1px solid var(--text-1-o);
               font-size: 12px;
               min-height: 50px;
               resize: vertical;
            }
            .fxBad {
               outline: 1px solid var(--accent-0) ;
            }
            .fxHelper {
               padding: 4px;
               background-color: var(--body-bgc-1);
               border: 1px solid var(--text-1-o);
               .fxHelperHeader {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  > p {
                     font-size: 12px;
                  }
               }
               .fxHelperConfigs {
                  display: flex;
                  gap: 0.4rem;
                  flex-wrap: wrap;
                  margin-bottom: 6px;
                  >div {
                     flex-grow: 0;
                     width: fit-content;
                     // background-color: transparent;
                     gap: 1rem;
                     outline: none;
                     font-size: 12px;
                  }
               }
               .fxSuggestions {
                  display: flex;
                  flex-direction: column;
                  gap: 4px;
                  .fxSuggestion {
                     background-color: var(--bgc-1);
                     padding: 4px 8px;
                     border: 1px solid var(--text-1-o);
                     border-radius: 4px;
                     display: flex;
                     justify-content: space-between;
                     cursor: pointer;
                     &:hover {
                        background-color: var(--main-1-o);
                     }
                     .fxSuggestionL {
                        font-size: 12px;
                        .fxSuggestionLabel {
                           font-weight: 700;
                        }
                        .fxSuggestionName {
                           font-size: 10px;
                           text-decoration: underline;
                           font-style: italic;
                        }
                        .fxSuggestionDesc {
                           white-space: pre-line;
                           font-size: 10px;
                        }
                     }
                     .fxSuggestionR {
                        > p {
                           font-size: 12px;
                           font-style: italic;
                        }
                     }
                  }
               }
               .fxDeps {
                  .fxDep {
                     > b {
                        color: var(--text-0-o-50);
                        font-size: 10px;
                        text-decoration: underline;
                     }
                     >p {
                        font-size: 12px;
                        white-space: pre-line;
                     }
                     >span {
                        font-size: 12px;
                     }
                  }
               }
            }
         }
      }
      .errorC {
         display: flex;
         margin: 0.4rem;
         flex-direction: column;
         align-items: center;
         .error {
            font-size: 12px;
            color: var(--main-3);
         }
      }
      .controlsC {
         display: flex;
         justify-content: space-between;
      }
   }
}
