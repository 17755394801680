.contentC {
   position: relative;
   min-height: 100vh;
   background-color: var(--body-bgc-1);
   .bulkInspectorC {
      background-color: var(--bgc-1);
      padding: 12px 0 12px 1rem;
      display: flex;
      gap: 8px;
      align-items: center;
      position: sticky;
      top: 0;
      left: 0;
      z-index: 1;
   }
   .headerC {
      padding: 1rem 1rem 8px 1rem;
      background-color: var(--bgc-1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      top: 0;
      right: 0;
      z-index: 1;
      .headerL {
         align-items: center;
         display: flex;
         gap: 8px;
         width: 100%;
         min-width: 0;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
         > :first-child {
            flex-shrink: 0;
         }
         > .nameC {
            > p {
               font-size: 12px;
               color: var(--text-1-o);
            }
            > b {
               font-size: 14px;
            }
         }
      }
   }
}
