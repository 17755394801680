.contentC {
   padding: 1rem;
   .tabsC {
      padding: 12px;
      border-radius: 10px;
      background-color: var(--bgc-1);
      margin-bottom: 1rem;
      .tabsHeader {
         margin-bottom: 8px;
         display: flex;
         justify-content: space-between;
         align-items: center;
      }
      .tabs {
         display: flex;
         gap: 14px;
         overflow-y: auto;
         > button {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: fit-content;
            background-color: var(--body-bgc-1);
            padding: 8px;
            border-radius: 10px;
            gap: 6px;
            transition: 0.4s ease all;
            > img {
               // margin: auto;
               height: 26px;
               width: 26px;
               filter: var(--text-1-filter);
            }
            > b {
               white-space: nowrap;
               font-size: 10px;
            }
         }
         .active {
            background-color: var(--main-1);
         }
         > button:hover {
            background-color: var(--text-1);
            > img {
               filter: var(--bgc-1-filter);
            }
            > b {
               color: var(--bgc-1);
            }
         }
      }
   }
   .expandTabs {
      .tabs {
         flex-wrap: wrap;
      }
   }
}
