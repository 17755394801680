.contentC {
   padding: 1rem;
   .headerC {
      display: flex;
      gap: 1rem;
      margin-bottom: 1rem;
   }
   .fields {
      margin-top: 1rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0.4rem;
      .fieldC {
         padding: 0.6rem 1rem;
         border: 1px solid var(--text-1-o);
         width: 20rem;
         cursor: pointer;
         display: flex;
         align-items: center;
         justify-content: space-between;
         &:hover {
            background-color: var(--text-1-o);
         }
         .field {
            max-width: 15rem;
            display: flex;
            flex-direction: column;
            gap: 0.2rem;
            .name {
               font-weight: 700;
               font-size: 18px;
            }
            .readonly {
               font-size: 12px;
               font-style: italic;
            }
            .column,
            .uwColumn {
               white-space: nowrap;
               text-overflow: ellipsis;
               overflow: hidden;
               > span {
                  font-style: italic;
               }
            }
            .formula {
               font-size: 14px;
               color: var(--bgc-1);
               background-color: var(--text-1);
               padding: 0px 6px;
               white-space: nowrap;
               text-overflow: ellipsis;
               overflow: hidden;
               @supports (-webkit-line-clamp: 2) {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: initial;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
               }
            }
         }
      }
   }
}
