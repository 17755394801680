.contentC {
   display: flex;
   height: 460px;
   width: 100%;
   overflow-x: auto;
   .reelC{
      display: flex;
      flex-direction: column;
      height: 100%;
      flex-wrap: wrap;
      >img {
         height: 50%;
      }
   }
}