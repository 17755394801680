.contentC {
   display: flex;
   flex-direction: column;
   .uwsC {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      .searchC {
         position: sticky;
         z-index: 1;
         top: 0;
         right: 0;
         background-color: var(--bgc-1);
         padding: 1rem;
         width: 100%;
         display: flex;
         align-items: center;
         justify-content: space-between;
         > :first-child {
            flex-grow: 1;
            max-width: 400px;
            display: flex;
            gap: 1rem;
            align-items: center;
         }
         .searchR {
            display: flex;
            gap: 8px;
         }
      }
      .uwsHeader {
         padding: 1rem;
         width: 100%;
         display: flex;
         justify-content: space-between;
         .uwsHeaderL {
            display: flex;
            flex-direction: column;
            // align-items: center;
            gap: 8px;
            .uwsHeaderL1 {
               display: flex;
               gap: 8px;
               > div {
                  width: fit-content;
               }
            }
            .uwsHeaderL2 {
               display: flex;
               align-items: center;
               gap: 1rem;
               > a {
                  > p {
                     display: flex;
                     align-items: center;
                     gap: 4px;
                     font-size: 12px;
                     > span {
                        text-decoration: underline;
                     }
                     > img {
                        height: 10px;
                        filter: var(--text-1-filter);
                     }
                  }
               }
            }
         }
         .uwsHeaderR {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 8px;
            .uwsHeaderR1,
            .uwsHeaderR2,
            .uwsHeaderR3 {
               display: flex;
               align-items: center;
               gap: 8px;
            }
         }
      }
      .uws {
         display: flex;
         gap: 1rem;
         flex-wrap: wrap;
         justify-content: center;
         background-color: var(--body-bgc-1);
         width: 100%;
         .tableC {
            padding: 2px 1rem;
            width: 100%;
            overflow-x: auto;
         }
      }
   }
}
