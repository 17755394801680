.contentC {
   padding: 1rem;
   background-color: var(--bgc-1);
   border-radius: 10px;
   width: 40rem;
   .controls {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
         display: flex;
         align-items: center;
         gap: 1rem;
      }
   }
   .differences {
      display: flex;
      height: 20rem;
      overflow-y: overlay;
   }
}
