.revPopup {
   background-color: var(--body-bgc-1);
   height: 100%;
   display: flex;
   flex-direction: column;
   flex-shrink: 0;
   flex-grow: 0;
   .revPopupHeader {
      padding: 1rem;
      background-color: var(--bgc-1);
      display: flex;
      gap: 1rem;
      align-items: center;
      width: 100%;
      > button:first-child {
         flex-shrink: 0;
      }
      > div:last-child {
         width: 80%;
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         > span {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
         }
      }
   }
   .revPopupForm {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-bottom: 20rem;
      flex-grow: 1;
      overflow-y: auto;
   }
}
