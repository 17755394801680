.contentC {
   background-color: var(--body-bgc-1);
   height: 100%;
   .headerC {
      padding: 0 1rem;
      height: 64px;
      background-color: var(--bgc-1);
      display: flex;
      gap: 1rem;
      align-items: center;
   }
   .filtersC {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 0 1rem 1rem 1rem;
      height: 50px;
      background-color: var(--bgc-1);
   }
   .nofsC {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      height: calc(100vh - 65px - 50px);
      overflow-y: auto;
      gap: 12px;
   }
}
