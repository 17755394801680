.contentC {
   background-color: var(--body-bgc-1);
   .headerC {
      display: flex;
      justify-content: space-between;
      background-color: var(--bgc-1);
      padding: 1rem;
   }
   .versionsC {
      .versionsCountC {
         padding: 1rem;
      }
      .versionsSearch {
         margin-inline: auto;
         margin-top: 1rem;
         margin-bottom: 1rem;
         width: min(90%, 400px);
      }
      .versions {
         display: flex;
         flex-wrap: wrap;
         gap: 1rem;
         justify-content: center;
         .versionC {
            background-color: var(--bgc-1);
            padding: 12px;
            border-radius: 10px;
            box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
            .versionHeaderC {
               .version {
                  font-weight: 700;
                  font-size: 20px;
               }
               .description {
                  font-size: 12px;
               }
            }
            .versionBodyC {
               .created {
                  font-size: 12px;
                  color: var(--text-1-o);
               }
            }
            &:hover {
               outline: 2px solid var(--main-1);
               background-color: var(--bgc-2);
            }
         }
      }
   }
}
