.contentC {
   padding: 2rem 2rem;
   height: 20rem;
   display: flex;
   flex-direction: column;
   gap: 1rem;
   align-items: center;
   justify-content: center;
   user-select: none;
   .msg {
      text-align: center;
      font-weight: 700;
      // font-size: 14px;
   }
}
