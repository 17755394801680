.viewTabC {
   background-color: var(--body-bgc-1);
   height: 100%;
   padding-bottom: 2rem;
   display: flex;
   flex-direction: column;
   .viewTabHeader {
      background-color: var(--bgc-1);
      padding: 1rem 1rem 8px 1rem;
      display: flex;
      gap: 1rem;
      align-items: center;
   }
   .tabs {
      padding: 0 1rem;
      background-color: var(--bgc-1);
      margin-bottom: 1rem;
   }
   .inputsC , .shareC{
   }
   .inputsC {
      padding: 1rem;
      margin: 0 1rem;
      background-color: var(--bgc-1);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      .inputs1 {
         display: flex;
         justify-content: space-between;
         width: 100%;
         white-space: nowrap;
      }
      .inputs2 {
         width: 100%;
         padding: 8px;
         background-color: var(--body-bgc-1);
         display: flex;
         flex-direction: column;
         gap: 8px;
         .inputs2L,
         .inputs2R {
            display: flex;
            align-items: center;
            gap: 8px;
            white-space: nowrap;
         }
      }
      .selected{
         padding: 8px;
         background-color: var(--body-bgc-1);
         display: flex;
         flex-wrap: wrap;
         gap: 8px;
         max-height: 100px;
         align-items: center;
         overflow-y: auto;
         >button {
            display: flex;
            gap: 8px;
            padding: 2px 4px;
            border: 1px solid var(--text-1);
            border-radius: 20px;
          >:last-child {
            transform: rotate(-45deg);
          }
          &:hover {
            background-color: var(--text-1);
            > span {
               color: var(--bgc-1);
            }
         }
         }
      }
      .tableC {
         flex-shrink: 0;
         padding: 2px;
         width: 100%;
         overflow-x: auto;
      }
   }
   .shareC {
      background-color: var(--bgc-1);
      padding: 1rem;
      margin: 0 1rem;
      .usersC {
         margin-top: 1rem;
         display: flex;
         flex-wrap: wrap;
         gap: 8px;
         > li {
            padding: 0 8px;
            outline: 1px solid var(--text-1-o);
            border-radius: 20px;
            position: relative;
            padding-right: 16px;
            cursor: pointer;
            &::after {
               right: 4px;
               top: 50%;
               line-height: 4px;
               display: flex;
               align-items: center;
               justify-content: center;
               position: absolute;
               content: "+";
               display: block;
               transform: rotate(-45deg) translateY(-50%);
            }
            &:hover {
               outline-width: 2px;
            }
         }
      }
   }
}
