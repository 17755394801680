.contentC {
   position: relative;
   .note {
      scroll-margin: 16px; //* for scrolling padding
      padding: 1rem;
      background-color: var(--bgc-1);
      border-radius: 10px;
      position: relative;
      // background-color: red;
      width: 100%;
      &:hover {
         background-color: var(--scnd-1-o-30);
         .hoverControls {
            display: block;
         }
      }
      .hoverControls {
         position: absolute;
         top: 0;
         right: 1rem;
         transform: translateY(-50%);
         display: none;
      }
      .noteHeader {
         display: flex;
         justify-content: space-between;
         > b {
            text-transform: capitalize;
         }
         > p {
            font-size: 12px;
         }
      }
   }
   .highlightedNote { 
      outline: 4px solid var(--main-1);
   }
   .replying {
      background-color: var(--scnd-1-o-30);
   }
   .repliesBtn {
      margin-left: 1rem;
   }
   .repliesC {
      position: relative;
      margin-left: 1rem;
      padding-left: 1rem;
      padding-top: 1rem;
      display: flex;
      flex-direction: column-reverse;
      gap: 2rem;
      .sideBarC {
         position: absolute;
         top: 0;
         left: 0px;
         flex-grow: 1;
         bottom: 0;
         width: 14px;
         .sideBar {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: var(--text-1-o-10);
            border-radius: 10px;
            width: 2px;
         }
         &:hover {
            .sideBar {
               background-color: var(--text-1-o);
            }
         }
      }
   }
   .tiny {
      width: 100%;
      margin-top: 10px;
      padding-left: 1rem;
   }
}


