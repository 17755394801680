.contentC {
   background-color: var(--bgc-1);
   padding: 1rem;
   border-radius: 20px;
   width: min(600px, 90%);
   .headerC {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
   }
   .formC{
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: space-between;
      > :first-child {
         flex-grow: 1;
         max-width: 80%;
      }
   }
}