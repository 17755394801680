.tiny {
   position: relative;
   flex-grow: 1;
   flex-shrink: 0;
   > :last-child {
      position: absolute;
      z-index: 2;
      top: 1rem;
      right: 1rem;
      display: flex;
      gap: 10px;
      align-items: center;
   }
}
