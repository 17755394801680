.contentC {
   background-color: var(--bgc-1);
   padding: 1rem;
   border-radius: 10px;
   height: 30rem;
   width: 60rem;
   padding: 1rem;
   .builder {
      display: flex;
      height: 100%;
      gap: 1rem;
      user-select: none;
      .components {
         scroll-behavior: contain;
         flex-grow: 1;
         overflow: auto;
         gap: 1rem;
         > div {
            display: flex;
            gap: 1rem;
            align-items: center;
         }
      }
      .dropables {
         width: 14rem;
         border-left: 1px solid var(--text-1-o);
         padding-left: 1rem;
         display: flex;
         flex-direction: column;
         gap: 0.6rem;
         >div:first-child {
            display: flex;
            align-items: center;
            gap: 8px;
         }
         .nodes {
            // height: 10rem;
            border-bottom: 1px solid var(--text-1-o);
            flex-grow: 1;
            flex-basis: 0;
            overflow-y: overlay;
            overflow-x: hidden;
            display: flex;
            flex-wrap: wrap;
            gap: 0.4rem;
         }
      }
   }
}
