.contentC {
   padding-bottom: 10rem;
   .headerC {
      padding: 1rem;
      display: flex;
      align-items: center;
      gap: 1rem;
      background-color: var(--bgc-1);
   }
   .tabsC {
      padding: 0 1rem;
      background-color: var(--bgc-1);
   }
   .assetC {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: min(800px, 96%);
      margin-inline: auto;
      gap: 20px;
      .assetPropDetails {
         width: 100%;
         background-color: var(--bgc-1);
         border-radius: 20px;
         padding-bottom: 30px;
         .assetPropDetailsHeader {
            display: flex;
            > li {
               flex-grow: 1;
               padding: 20px 0;
               display: flex;
               flex-direction: column;
               align-items: center;
               --liborder: 1px solid var(--text-1-o-10);
               border-bottom: var(--liborder);
               border-right: var(--liborder);
               > div {
                  margin-bottom: 8px;
                  > p {
                     font-weight: 700;
                     font-size: 18px;
                  }
               }
               > span {
                  color: var(--text-1-o);
               }
               &:last-child {
                  border-right: none;
               }
            }
         }
         .assetPropDetailsCards {
            display: flex;
            padding: 20px 30px;
            > li {
               background-color: var(--bgc-2);
               border-radius: 10px;
               padding: 20px 0;
               flex-grow: 1;
               display: flex;
               flex-direction: column;
               align-items: center;
               gap: 8px;
            }
         }
         .assetPropDetailsDesc {
            padding: 0 30px;
            > li {
               font-size: 14px;
               > p {
                  margin-bottom: 12px;
               }
               > div {
                  line-height: 140%;
               }
            }
         }
      }
      .assetRentDetails,.assetNhInsights {
         width: 100%;
         background-color: var(--bgc-1);
         padding: 30px 30px 0 30px;
         border-radius: 20px;
         > h1 {
            margin-bottom: 1rem;
         }
         > ul {
            display: flex;
            flex-wrap: wrap;
            > li {
               width: 25%;
               margin-bottom: 30px;
               > p {
                  font-size: 14px;
                  font-weight: 400;
                  color: var(--text-1-o);
                  margin-bottom: 8px;
               }
            }
         }
      }
   }
}
