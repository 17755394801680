.contentC {
   background-color: var(--body-bgc-1);
   padding-bottom: 50vh;
   .header {
      background-color: var(--bgc-1);
      padding: 16px 30px 16px 30px;
      display: flex;
      gap: 1rem;
      justify-content: space-between;
      flex-wrap: wrap;
      // align-items: center;
      .infoC {
         > :not(:last-child) {
            margin-bottom: 8px;
         }
         .infoRow {
            display: flex;
            gap: 1rem;
            white-space: nowrap;
            > div {
               width: 9rem;
               > p {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
               }
               > p:first-child {
                  color: var(--text-1-o);
               }
               > p:last-child {
                  > a {
                     display: flex;
                     align-items: center;
                     gap: 10px;
                     > img {
                        filter: var(--main-1-filter);
                     }
                  }
               }
            }
         }
      }
      .controlsC {
         > :not(:last-child) {
            margin-bottom: 1rem;
         }
         > div {
            display: flex;
            gap: 14px;
         }
      }
   }
   .ud {
      padding: 1rem;
      .udHeader {
         display: flex;
         align-items: center;
         gap: 1rem;
         margin-bottom: 1rem;
      }
      .udChildren {
         display: flex;
         flex-wrap: wrap;
         gap: 1rem;
         justify-content: center;
         // align-items: center;
         .udChild {
            border: 1px solid var(--text-1-o);
            border-radius: 14px;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: fit-content;

            .udChildTitle {
               font-weight: 700;
               font-size: 18px;
               display: flex;
               align-items: center;
               gap: 1rem;
            }
         }
      }
   }
   .pd {
      padding: 1rem;

      .pdHeader {
         display: flex;
         gap: 1rem;
         margin-bottom: 1rem;
         align-items: center;
      }
   }
   .Groups {
      background-color: var(--body-bgc-1);
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      margin-bottom: 1rem;
      justify-content: center;
      width: fit-content;
      max-width: 100%;
      overflow-x: overlay;
      .Group {
         max-width: 100%;
         // overflow-x: overlay;
         // overflow-y: visible;
         border-radius: 14px;
         background-color: var(--bgc-1);
         padding: 16px 20px;
         width: fit-content;
         .GroupTitle {
            display: flex;
            align-items: center;
            font-size: 18px;
            margin-bottom: 1rem;
            white-space: nowrap;
         }
         .titlesC {
            display: flex;
            // overflow-x: overlay;
            flex-wrap: wrap;
            gap: 1rem;
         }
      }
   }
}

// @media screen and (max-width: 1025px) {
//    .contentC {
//       .Groups {
//          justify-content: center;
//       }
//    }
// }
@media screen and (max-width: 769px) {
   .contentC {
      .header {
         padding: 0 1rem 24px 1rem;
         justify-content: space-between;
         .infoC {
            display: flex;
            overflow-x: overlay;
            .infoRow {
               flex-shrink: 0;
            }
         }
      }
   }
}

@media screen and (max-width: 426px) {
   .contentC {
      .Groups {
         max-width: 100%;
         .Group {
            .titlesC {
               overflow-x: overlay;
               max-width: 100%;
               flex-wrap: nowrap;
            }
         }
      }
   }
}
