.contentC {
   padding: 1rem;
   background-color: var(--bgc-1);
   border-radius: 20px;
   .headerC {
      margin-bottom: 12px;
   }
   .clsC {
      background-color: var(--body-bgc-1);
      padding: 1rem;
      > li {
         padding: 12px;
         background-color: var(--bgc-1);
         border-radius: 10px;
         margin-bottom: 1rem;
         .clHeader {
            display: flex;
            justify-content: space-between;
            > b {
               text-transform: capitalize;
            }
            > p {
               font-size: 12px;
            }
         }
      }
   }
}
