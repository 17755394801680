.contentC {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 8px 14px;
   background-color: var(--bgc-2);
   border-radius: 10px;
   cursor: pointer;
   user-select: none;
   position: relative;
   outline: 1px solid var(--bgc-2);
   transition: 0.4s ease all;
   min-height: 50px;
   box-shadow: 0px 10px 30px -5px rgba(34, 37, 46, 0.1);
   &:hover {
      outline: 1px solid var(--main-1);
   }
   .left {
      flex-grow: 1;
      .titleC {
         display: flex;
         align-items: center;
         gap: 4px;
         .title {
            font-size: 14px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            color: var(--text-1-o);
            color: var(--scnd-1);
         }
         .lock {
            position: relative;
            transition: 0.4s all ease-in-out;
            filter: var(--scnd-1-filter);
            height: 1rem;
         }
         .lockFocused {
            transform: scale(1.4);
         }
      }

      .inputC {
         display: flex;
         justify-content: space-between;
         flex-grow: 1;
         transition: 0.4s ease all;
         max-height: 18px;
         .input,
         textarea,
         .dropdownVal {
            font-size: 16px;
            width: 100%;
         }
         textarea {
            resize: vertical;
         }
         .colorC {
            display: flex;
            gap: 0.4rem;
            .colorPreview {
               border: 1px solid var(--text-1);
               width: 1rem;
               height: 1rem;
               aspect-ratio: 1/1;
               border-radius: 50%;
            }
         }
         .dropdownOptions {
            padding: 8px;
            background-color: var(--bgc-1);
            box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            width: calc(100%);
            position: absolute;
            top: calc(100%);
            left: 0;
            cursor: pointer;
            z-index: 1;
            max-height: 10rem;
            overflow-y: overlay;
            overflow-x: hidden;
            > div {
               font-size: 14px;
               line-height: 16px;
               padding: 8px;
               min-width: 5rem;
               background-color: var(--bgc-1);
               border-radius: 8px;
               display: flex;
               align-items: center;
               justify-content: space-between;
               &:hover {
                  background-color: var(--scnd-1-o);
               }
               .checkbox {
                  filter: var(--main-1-filter);
               }
            }
            .selected {
               background-color: var(--main-1);
               &:hover {
                  background-color: var(--main-3);
               }
            }
         }
      }
   }
   .right{
      .lens {
         filter: var(--scnd-1-filter);
      }
      .ddArrow {
         filter: var(--scnd-1-filter);
         transition: 0.4s ease all;
      }
      .ddArrowRotate {
         rotate:  180deg;
      }
   }
}
.hideInput {
   .left {
      .inputC {
         max-height: 0;
      }
   }
}
.checkbox {
   background-color: transparent;
   height: fit-content;
   outline: none;
   &:hover {
      outline: none;
   }
   .left {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;
      gap: 12px;
      .titleC {
         .checked {
            color: var(--text-1);
         }
      }
      .inputC {
         flex-grow: 0;
         position: relative;
         input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
         }
         .checkmark {
            position: relative;
            // top: 0;
            // left: 0;
            height: 20px;
            width: 20px;
            background-color: var(--bgc-1);
            outline: 1.5px solid var(--main-1);
            border-radius: 3px;
         }
         .checkmark::before,
         .checkmark::after {
            content: "";
            position: absolute;
            display: none;
            width: 100%;
            height: 100%;
         }
         input:checked ~ .checkmark::before {
            display: block;
            background-color: var(--main-1);
         }
         input:checked ~ .checkmark::after {
            display: block;
            left: 7px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
         }
      }
   }
}

.bad {
   border: 1px solid red;
}
