.contentC {
  background-color: var(--bgc-1);
  padding-bottom: 5vh;
  border-radius: 30px;
  width: min(450px, 90vw);

   .headerC {
      padding: 16px 20px 16px 20px;
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      align-items: center;
      border-radius: 50px;
   }
   .calBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    .calInput{
      text-align: center;
      width: 69%;
    }
   }
}