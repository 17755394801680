.contentC {
   height: 100%;
   padding: 1rem;
   background-color: var(--bgc-1);
   display: flex;
   flex-direction: column;
   .headerC {
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      gap: 1rem;
      .header1 {
        
      }
      .header2 {
         .title {
         }
         .fieldInfoC {
            display: flex;
            gap: 6px;
            flex-wrap: wrap;
            > li {
               white-space: nowrap;
               font-size: 14px;
            }
         }
         .crumbs {
            display: flex;
            flex-wrap: wrap;
            > li {
               > button {
                  color: var(--text-1-o);
                  font-size: 12px;
                  &:hover {
                     color: var(--text-1);
                     text-decoration: underline;
                  }
               }
               .crumActive {
                  color: var(--text-1);
                  text-decoration: underline;
               }
            }
         }
      }
   }
   .translatedC {
      background-color: var(--text-1);
      padding: 0.4rem;
      margin-bottom: 1rem;
      flex-shrink: 0;
      max-height: 69px;
      overflow-y: auto;
      overflow-x: hidden;
      > code {
         color: var(--bgc-1);
      }
   }
   .valueC {
      display: flex;
      gap: 1rem;
      align-items: center;
      width: fit-content;
      margin-bottom: 1rem;
   }
   .tabsC {
   }
   .inputsC {
      flex-grow: 1;
      overflow-y: auto;
      overflow-x: hidden;
      background-color: var(--body-bgc-1);
      padding: 0.6rem;
      display: flex;
      flex-direction: column;
      gap: 0.6rem;
      padding-bottom: 4rem;
      .group {
         padding: 0.6rem;
         border-left: 2px solid var(--text-1);
         
         .groupTitle {
            margin-bottom: 0.2rem;
            font-weight: 700;
         }
         .groupInputs {
            display: flex;
            flex-wrap: wrap;
            gap: 0.4rem;
         }
      }
   }
}
