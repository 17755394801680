.contentC{
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .contentpanelC{
    width: min(90%, 600px);
    background-color: var(--bgc-1);
    padding: 1rem;
    border-radius: 20px;
    .header{
      margin-bottom: 1rem;
    }

    .userName{
      display: flex;
      justify-content: center;
      gap: 1rem;
      margin-bottom: 1rem;
    
    }
    .submitButton{
      display: flex;      
      justify-content: flex-end;
      
    }
  }
}