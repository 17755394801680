.contentC {
   border-collapse: collapse;
   border-radius: 20px 20px 0 0;
   font-size: 12px;
   position: relative;
   > thead {
      > tr {
         > th {
            background-color: var(--main-2);
            text-align: left;
            cursor: pointer;
            &:hover {
               outline: 1px solid var(--scnd-1);
            }
            &:first-child {
               border-radius: 20px 0 0 0;
            }
            &:last-child {
               border-radius: 0 20px 0 0;
            }
            > div {
               padding: 10px;
               display: flex;
               align-items: center;
               gap: 6px;
               height: 100%;
               filter: var(--text-1-filter);
               > span {
                  white-space: nowrap;
               }
               > img {
                  transition: 0.2s all ease;
               }
            }
            .asc {
               filter: var(--main-1-filter);
               > img {
                  transform: rotate(-90deg);
               }
            }
            .desc {
               filter: var(--main-1-filter);
               > img {
                  transform: rotate(90deg);
               }
            }
         }
         .dropCanDrop {
            background-color: var(--main-1-o);
         }
         .dropIsOver {
            background-color: var(--main-1);
            outline: 2px solid var(--scnd-1);
         }
      }
   }
   > tbody {
      font-weight: 400;
      background-color: var(--bgc-1);
      > tr {
         > td {
            border-bottom: 1px solid var(--text-1-o-10);
            padding: 10px;
            white-space: nowrap;
            &:hover {
               outline: 2px solid var(--scnd-1);
            }
         }
         &:hover {
            outline: 1px solid var(--scnd-1);
         }
         .sorted {
            background-color: var(--main-2-o);
         }
      }
      .stickyFirstColumn {
         background-color: var(--main-1);
      }
      .highlight {
         background-color: var(--main-1-o);
      }
   }
}
.stickyFirstRow,
.stickyFirstColumn {
   position: sticky;
   top: 0;
   left: 0;
}

.bold {
   font-weight: 700;
}
