.contentC {
   padding: 1rem;
   user-select: none;
   .viewsC {
      background-color: var(--background-1);
      padding: 6px;
      border-radius: 10px;
      .viewTabsC {
         margin-bottom: 0.5rem;
         border-bottom: 1px var(--text-0-o-10) solid;
         display: flex;
         .viewTabs {
            display: flex;
            width: 100%;
            overflow-x: auto;
            white-space: nowrap;
         }
      }
      .viewConfig {
         margin-bottom: 0.5rem;
         .viewControlls {
            display: flex;
            align-items: center;
            gap: 1rem;
         }
      }
      .view {
         background-color: var(--background-0);
         padding: 8px 8px 8px 8px;
         .viewTypeTabs {
            background-color: var(--background-1);
            border-bottom: 1px var(--text-0-o-10) solid;
         }
         // display: flex;
         // gap: 1rem;
      }
   }
}

.groupsC {
   padding: 8px;
   background-color: var(--background-1);
   // width: 50%;
   .groupTabsC {
      display: flex;
      margin-bottom: 8px;
      border-bottom: 1px var(--text-0-o-10) solid;
      .groupTabs {
         display: flex;
         width: 100%;
         overflow-x: auto;
         white-space: nowrap;
      }
   }
   .groupConfig {
      margin-bottom: 0.5rem;
      .groupControlls {
         display: flex;
         align-items: center;
         gap: 1rem;
         margin-bottom: 8px;
      }
      .groupType1 {
         padding: 8px;
         background-color: var(--background-0);
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         gap: 1rem;
         width: 100%;
         white-space: nowrap;
         .groupType1Field {
            width: 100%;
         }
      }
   }
}

.wFit {
   width: fit-content;
}
