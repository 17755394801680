.contentC {
   background-color: var(--bgc-1);
   padding: 2rem;
   border-radius: 20px;
   height: 40rem;
   width: 40rem;
   overflow-y: overlay;
   > :not(:last-child) {
      margin-bottom: 0.4rem;
   }
   .title {
   }
   .inputsC {
      display: flex;
      align-items: center;
      gap: 0.4rem;
      > * {
         min-width: 8rem;
      }
   }
   .preview {
      padding: 0.4rem 1rem;
      border: 1px solid var(--text-1);
      .buttons {
         margin-top: 0.4rem;
         display: flex;
         justify-content: flex-end;
         gap: 1rem;
         > button {
            width: 6rem;
         }
      }
   }
   .tableC {
      width: 100%;
      overflow-x: overlay;
   }
}
