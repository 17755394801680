.filterText {
   filter: var(--text-filter);
}
.filterAccent {
   filter: var(--accent-filter);
}
.filterTitle {
   filter: var(--title-filter);
}
.contentC {
   --bad: #e72844;
   --title: #385b79;
   --title-filter: invert(32%) sepia(18%) saturate(1270%) hue-rotate(166deg) brightness(92%) contrast(85%);
   --text: #1a2d3d;
   --text-filter: brightness(0) saturate(100%) invert(15%) sepia(18%) saturate(1377%) hue-rotate(166deg) brightness(91%)
      contrast(93%);
   --accent: #9bd0b0;
   --accent-filter: invert(72%) sepia(43%) saturate(197%) hue-rotate(91deg) brightness(100%) contrast(89%);
   --accent-o: hsla(144, 36%, 71%, 0.5);
   --background: #f8f8f8;
   position: relative;
   padding: 6px 10px;
   background-color: var(--background);
   border-radius: 10px;
   transition: 0.4s ease all;
   box-shadow: 0px 10px 30px -5px rgba(34, 37, 46, 0.1);
   outline: 1px solid var(--background);
   display: flex;
   justify-content: space-between;
   width: 100%;
   &:hover {
      outline: 1px solid var(--accent);
      .contentL {
         .contentLT {
            > label {
               text-decoration: underline;
            }
         }
      }
   }
   .contentL {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      .contentLT {
         display: flex;
         gap: 4px;
         user-select: none;
         > label {
            font-size: 12px;
            color: var(--title);
            cursor: pointer;
         }
         .tooltipIcon {
            background-color: var(--title);
            border-radius: 50%;
            height: 12px;
            width: 12px;
            text-align: center;
            &:hover {
               .tooltip {
                  display: block;
               }
            }
            > span {
               font-weight: 700;
               display: block;
               color: var(--background);
               font-size: 10px;
            }
            .tooltip {
               display: none;
               width: 100%;
               font-size: 10px;
               position: absolute;
               top: calc(100% + 4px);
               left: 0;
               white-space: pre-line;
               background-color: var(--accent-o);
               border-radius: 6px;
               padding: 2px;
            }
         }
      }
      > input,
      .input {
         width: 100%;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
         height: 16px;
         font-size: 14px;
      }
   }
   .contentR {
      .checkboxC {
         > input {
            display: none;
         }
         .checkmark {
            display: block;
            position: relative;
            height: 16px;
            width: 16px;
            background-color: var(--background);
            outline: 1.6px solid var(--accent);
            border-radius: 3px;
         }
         .checkmark::before,
         .checkmark::after {
            content: "";
            position: absolute;
            display: none;
            width: 100%;
            height: 100%;
         }
         > input:checked ~ .checkmark::before {
            display: block;
            background-color: var(--accent);
         }
         > input:checked ~ .checkmark::after {
            display: block;
            left: 5px;
            top: 1px;
            width: 4px;
            height: 8px;
            border: solid var(--title);
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
         }
      }
      .toggleC {
         > input {
            display: none;
         }
         .toggleBall {
            position: relative;
            background-color: var(--background);
            outline: 1.5px solid var(--accent);
            border-radius: 20px;
            height: 1rem;
            width: 2rem;
            transition: 0.4s ease all;
         }
         .toggleBall::before {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 50%;
            left: 4px;
            content: "";
            width: 12px;
            height: 12px;
            display: inline-block;
            background-color: var(--title);
            transition: 0.4s ease all;
         }
         > input:checked ~ .toggleBall {
            background-color: var(--accent);
         }
         > input:checked ~ .toggleBall::before {
            background-color: var(--title);
            left: calc(2rem - 4px - 12px);
            // right: 4px;
         }
      }
      .lens {
         cursor: pointer;
      }
      .ddArrow {
         filter: var(--scnd-1-filter);
         transition: 0.4s ease all;
      }
      .ddArrowRotate {
         rotate: 180deg;
      }
   }
   .optionsC {
      position: absolute;
      z-index: 1;
      top: calc(100% + 6px);
      left: 0;
      border-radius: 10px;
      padding: inherit;
      background-color: var(--background);
      width: 100%;
      // display: flex;
      // flex-direction: column;
      // gap: 6px;
      max-height: 200px;
      overflow-y: auto;
      > li {
         padding: 0px 4px 2px 4px;
         margin-bottom: 6px;
         border-radius: 6px;
         cursor: pointer;
         display: flex;
         justify-content: space-between;
         &:hover {
            background-color: var(--accent-o);
         }
         > div:first-child {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            > b {
               font-size: 12px;
            }
            > p {
               font-size: 10px;
            }
         }
      }
   }
}

.bad {
   outline: 1px solid var(--bad);
}
