.contentC {
   display: flex;
   flex-direction: column;
   align-items: center;
   padding-top: 10rem;
   .title{
      margin-bottom: 6rem;
   }
   .app {
      position: relative;
      z-index: 100;
   }
}