.contentC {
 
   .headerC {
      display: flex;
      padding: 1rem;
      justify-content: space-between;
      align-items: center;
      background-color: var(--bgc-1);
   }
   .bodyC {
      .bodyHeaderC {
         display: flex;
         padding: 1rem;
         justify-content: space-between;
         align-items: center;
         .bodyHeaderR {
            display: flex;
            gap: 8px;
         }
      }
      .renovationsC {
         .tableC{
            padding: 2px 1rem;
            width: 100%;
            overflow-x: auto;
         }
         .cardsC {
            display: flex;
            gap: 1rem;
            flex-wrap: wrap;
            justify-content: center;
            > li {
               cursor: pointer;
               width: 280px;
               background-color: var(--bgc-1);
               border-radius: 10px;
               overflow: hidden;
               transition: all 0.4s ease;
               &:hover {
                  outline: 4px solid var(--text-1);
                  transform: translateY(-10px);
               }
               .imgC {
                  height: 180px;
                  width: 100%;
                  > img {
                     width: 100%;
                     height: 100%;
                     object-fit: cover;
                  }
               }
               .cardHeader {
                  padding: 1rem;
                  > p {
                     font-size: 12px;
                  }
               }
               .cardMid {
                  display: flex;
                  > div {
                     --border: 1px solid var(--text-1-o-10);
                     font-size: 12px;
                     flex-grow: 1;
                     height: 44px;
                     display: flex;
                     align-items: center;
                     justify-content: center;
                     border-right: var(--border);
                     border-top: var(--border);
                     &:last-child {
                        border-right: none;
                     }
                  }
               }
            }
         }
      }
   }
}
