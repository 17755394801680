.contentC {
   .headerC {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.6rem 1rem;
      .headerL {
      }

      .headerR {
      }
   }
   .fieldLists {
      display: flex;
      .fieldList {
         width: 50%;
         .fieldsCount {
            margin-inline: 1rem;
            margin-top: 1rem;
            display: flex;
            align-items: center;
            gap: 1rem;
         }
         &:last-child {
           border-left: 1px solid var(--text-0-o-10); 
         }
      }
   }
}
.fieldsC {
   display: flex;
   flex-wrap: wrap;
   gap: 1rem;
   justify-content: center;
   padding: 1rem;
   li {
      cursor: pointer;
      background-color: var(--background-1);
      padding: 0.6rem;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      transition: all 0.2s ease-in-out;
      width: 16rem;
      &:hover {
         background-color: var(--primary-0-o-50);
         outline: 1px solid var(--text-0-o-50);
      }
      .fieldRow {
         display: flex;
         justify-content: space-between;
         gap: 1rem;
      }
      .fieldLabel {
         font-size: 14px;
         font-weight: 700;
      }
      .fieldName {
         font-size: 12px;
         color: var(--text-0-o-50);
         font-style: italic;
         text-decoration: underline;
         margin-bottom: 6px;
      }
      .fieldId {
         font-size: 10px;
         font-weight: 700;
      }
      .fieldType {
         font-size: 12px;
      }
      .fieldDesc {
         font-size: 10px;
         white-space: normal;
         > span {
            text-decoration: underline;
         }
      }
      .fieldDescFlex {
         position: relative;
         display: flex;
         gap: 4px;
      }
   }
}
