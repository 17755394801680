.contentC {
   position: relative;
   .dragging {
       background-color: rgba(0, 0, 0, 0.25);
       height: 100%;
       width: 100%;
       position: absolute;
       top: 0;
       left: 0;
   }
}

.hidden {
   display: none;
}
