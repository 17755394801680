.contentC {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 6px 8px;
   background-color: var(--bgc-2);
   border-radius: 10px;
   cursor: pointer;
   user-select: none;
   position: relative;
   outline: 1px solid var(--bgc-2);
   transition: 0.4s ease all;
   box-shadow: 0px 10px 30px -5px rgba(34, 37, 46, 0.1);
   position: relative;
   &:hover {
      outline: 1px solid var(--main-1);
      .left {
         .title {
            text-decoration: underline;
         }
         .lock {
            transform: scale(1.4);
         }
      }
   }
   .left {
      flex-grow: 1;
      max-width: calc(100% - 3rem);
      .titleC {
         display: flex;
         align-items: center;
         .title {
            // margin-left: 4px;
            font-size: 12px;
            color: var(--scnd-1);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
         }
         .lock {
            position: relative;
            transition: 0.4s all ease-in-out;
            filter: var(--scnd-1-filter);
            height: 1rem;
         }
      }

      .inputC {
         display: flex;
         justify-content: space-between;
         flex-grow: 1;
         transition: 0.4s ease all;
         .input,
         .dropdownVal {
            font-size: 14px;
            width: 100%;
            width: fit-content;
            min-width: 5rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            flex-grow: 1;
         }
         textarea {
            resize: both;
            width: 100%;
         }
         .colorC {
            display: flex;
            gap: 0.4rem;
            .colorPreview {
               border: 1px solid var(--text-1);
               width: 1rem;
               height: 1rem;
               aspect-ratio: 1;
               border-radius: 50%;
            }
         }
      }
   }
   .right {
      display: flex;
      .lens {
         filter: var(--scnd-1-filter);
      }
      .ddArrow {
         filter: var(--scnd-1-filter);
         transition: 0.4s ease all;
      }
      .ddArrowRotate {
         rotate: 180deg;
      }
   }
   .dropdownOptions {
      padding: 8px;
      background-color: var(--bgc-1);
      box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      width: calc(100%);
      position: absolute;
      top: calc(100% + 0.6rem);
      left: 0;
      cursor: pointer;
      z-index: 1;
      overflow: hidden;
      // max-height: 10rem;
      animation: openSlowly 0.4s forwards;
      .ddoScrollContainer {
         max-height: 10rem;
         overflow-y: overlay;
         overflow-x: hidden;
         padding-bottom: 1rem;
         > div {
            font-size: 14px;
            line-height: 16px;
            padding: 8px;
            min-width: 5rem;
            background-color: var(--bgc-1);
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &:hover {
               background-color: var(--scnd-1-o);
            }
            .checkbox {
               filter: var(--main-1-filter);
               height: 16px;
            }
         }
         .selected {
            background-color: var(--main-1);
            &:hover {
               background-color: var(--main-3);
            }
         }
      }
   }
}
.hideInput {
   .left {
      .inputC {
         max-height: 0;
      }
   }
}
.checkbox,
.toggle {
   display: flex;
   flex-direction: row-reverse;
   justify-content: flex-end;
   align-items: center;
   gap: 12px;
   .inputC {
      flex-grow: 0;
      position: relative;
      > input {
         position: absolute;
         opacity: 0;
         cursor: pointer;
         height: 0;
         width: 0;
      }
   }
}
.checkbox {
   .inputC {
      .checkmark {
         position: relative;
         // top: 0;
         // left: 0;
         height: 20px;
         width: 20px;
         background-color: var(--bgc-1);
         outline: 1.5px solid var(--main-1);
         border-radius: 3px;
      }
      .checkmark::before,
      .checkmark::after {
         content: "";
         position: absolute;
         display: none;
         width: 100%;
         height: 100%;
      }
      input:checked ~ .checkmark::before {
         display: block;
         background-color: var(--main-1);
      }
      input:checked ~ .checkmark::after {
         display: block;
         left: 7px;
         top: 2px;
         width: 5px;
         height: 10px;
         border: solid #fff;
         border-width: 0 2px 2px 0;
         -webkit-transform: rotate(45deg);
         -ms-transform: rotate(45deg);
         transform: rotate(45deg);
      }
   }
}
.toggle {
   .inputC {
      .toggleBall {
         position: relative;
         // background-color: var(--text-1-o);
         background-color: var(--bgc-1);
         outline: 1.5px solid var(--main-1);
         border-radius: 20px;
         height: 1rem;
         width: 2rem;
         transition: 0.4s ease all;
      }
      .toggleBall::before {
         position: absolute;
         top: 50%;
         transform: translateY(-50%);
         border-radius: 50%;
         left: 4px;
         content: "";
         width: 12px;
         height: 12px;
         display: inline-block;
         // background-color: var(--bgc-1);
         background-color: var(--text-1-o);
         transition: 0.4s ease all;
      }
      input:checked ~ .toggleBall {
         background-color: var(--main-1);
      }
      input:checked ~ .toggleBall::before {
         background-color: var(--text-1);
         left: calc(2rem - 4px - 12px);
         // right: 4px;
      }
   }
}
.bad {
   border: 1px solid red;
}
.highlight {
   outline: 2px solid var(--text-1);
}
@keyframes openSlowly {
   from {
      max-height: 0;
   }
   to {
      max-height: 10rem;
   }
}
