.cellC {
   display: flex;
   align-items: center;
   gap: 0.4rem;
   .cell {
      font-weight: 700;
      > div:first-child {
         font-size: 12px;
         width: fit-content;
         background-color: var(--text-1);
         color: var(--text-2);
      }
   }
}
.valueC {
   display: flex;
   align-items: center;
   .value {
      font-weight: 700;
      > div:first-child {
         font-size: 12px;
         width: fit-content;
         background-color: var(--text-1);
         color: var(--text-2);
         text-transform: capitalize;
      }
      > label {
         width: 5rem;
         border: none;
         padding: 0;
         margin: 0;
      }
   }
}
.binary {
   display: flex;
   gap: 0.6rem;
   align-items: center;
   .operatorC {
      .operator {
         height: 1rem;
         width: 1rem;
         display: flex;
         justify-content: center;
         align-items: center;
         font-weight: 700;
      }
      > img {
         display: none;
      }
      &:hover {
         .operator {
            display: none;
         }
         > img {
            display: block;
         }
      }
   }
}
.function {
   width: fit-content;
   height: fit-content;
   padding: 0.6rem;
   border: 2px solid var(--text-1);
   border-radius: 10px;
   .nameC {
      display: flex;
      align-items: center;
      gap: 0.4rem;
      .name {
         font-weight: 700;
         > div:first-child {
            font-size: 12px;
            width: fit-content;
            background-color: var(--text-1);
            color: var(--text-2);
         }
      }
   }
   .args {
      margin-top: 0.6rem;
      // border-left: 1px solid var(--text-1-o);
      // padding-left: 0.4rem;
      .arg {
         margin-bottom: 0.4rem;
         padding-bottom: 0.4rem;
         border-left: 1px solid var(--text-1-o);
         border-bottom: 1px solid var(--text-1-o);
         border-radius: 0 0 0 6px;
         display: flex;
         gap: 0.6rem;
         align-items: center;
         padding-bottom: 0.4rem;
         > div:first-child {
            font-weight: 700;
            margin-left: 0.2rem;
         }
      }
      // display: flex;
      // gap: 1rem;
      //  > :not(:last-child) {
      // border-right: 1px solid red;
      // }
   }
}
.contentC {
}
.del {
   width: 1rem;
   height: 1rem;
   cursor: pointer;
   &:hover {
      outline: 2px solid var(--main-3);
      border-radius: 50%;
   }
}
