.uw {
   background-color: var(--bgc-1);
   width: 280px;
   max-width: 280px;
   position: relative;
   transition: 0.4s all ease;
   cursor: pointer;
   overflow: hidden;
   border-radius: 10px 10px 0 0;
   border: 2px solid var(--main-3);
   > a {
      --border: 1px solid var(--text-1-o-10);
      background-color: var(--bgc-1);
      box-shadow: 0px 20px 34px 0px rgba(59, 60, 68, 0.07);
      .uwImage {
         width: 100%;
         height: 180px;
         > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
         }
      }
      .uwCard1 {
         padding: 1rem;
         width: 100%;
         border-bottom: var(--border);
         > b {
            display: block;
            font-size: 10px;
            line-height: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 4px;
         }
         > p {
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
         }
      }
      .uwCard2 {
         display: flex;
         > li {
            flex-grow: 1;
            padding: 8px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            border-bottom: var(--border);
            &:not(:last-child) {
               border-right: var(--border);
            }
         }
      }
      .uwCard3 {
         padding: 1rem;
         font-size: 12px;
         > li {
            > span {
               color: var(--text-1-o);
            }
            &:not(:last-child) {
               margin-bottom: 8px;
            }
         }
      }
   }
   .uwLinkDisable {
      pointer-events: none;
   }
   .uwCheckBox {
      position: absolute;
      padding: 0 !important;
      top: 5px;
      left: 5px;
   }
   .uwBadgesC {
      position: absolute;
      top: 0px;
      right: 0;
      > li {
         font-size: 14px;
         // height: 16px;
         padding: 2px 8px;
         text-align: center;
      }
      .uwBadge1 {
         background-color: var(--main-4);
      }
      .uwBadge2 {
         font-size: 12px;
         font-weight: 700;
         background-color: var(--main-4);
      }
      .uwBadge3 {
         background-color: var(--main-1);
      }
   }
   &:hover {
      transform: translateY(-6px);
   }
}
.official{
   border: 6px solid var(--main-4);
}
.selected {
   
   outline: 3px solid var(--main-1);
}
