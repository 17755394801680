.contentC {
   background-color: var(--bgc-1);
   padding: 2rem;
   border-radius: 30px;
   max-height: 40rem;
   overflow-y: auto;
   .title {
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 1rem;
   }
   .content {
      display: flex;
      gap: 1rem;
      .form {
         width: 40rem;
         > :not(:last-child) {
            margin-bottom: 0.6rem;
         }
         .formulaC {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;
            > *:first-child {
               flex-grow: 1;
            }
         }
         .displayFormula {
            padding: 0.6rem;
            display: flex;
            flex-direction: column;
            .subTitle {
               font-weight: 700;
               margin-bottom: 0.4rem;
            }
            > :last-child {
               flex-grow: 1;
            }
         }
      }
      .finderC {
         width: 24rem;
         height: 20rem;
         border-left: 1px solid black;
         padding: 0 1rem;
         .fields {
            overflow-x: hidden;
            overflow-y: auto;
            height: 100%;
            .field {
               cursor: pointer;
               .type {
                  font-size: 8px;
               }
               .Database {
                  color: var(--main-1);
               }
               .Custom {
                  color: var(--main-3);
               }
               &:hover {
                  background-color: var(--text-1-o);
               }
            }
         }
      }
   }
   .controlsC {
      margin-top: 0.6rem;
      display: flex;
      gap: 2rem;
   }
}
