.contentC {
   --border: 1px solid var(--text-1-o-10);
   background-color: var(--bgc-1);
   width: 280px;
   border-radius: 10px;
   overflow: hidden;
   box-shadow: 0px 20px 34px 0px rgba(59, 60, 68, 0.07);
   transition: 0.4s ease all;
   &:hover {
      transform: translateY(-8px);
   }
   .strip {
      height: 0.6rem;
   }
   .imagesC {
      width: 100%;
      height: 180px;
      position: relative;
      text-align: center;
      .images {
         height: 100%;
         display: flex;
         transition: all 0.1s ease;
         .image {
            height: 100%;
            width: 280px;
            flex-shrink: 0;
            > img {
               width: 100%;
               height: 100%;
               object-fit: cover;
            }
         }
      }
      .btnL,
      .btnR {
         cursor: pointer;
         position: absolute;
         top: 50%;
         transform: translateY(-50%);
         height: 2rem;
         width: 2rem;
         line-height: 1rem;
         display: flex;
         align-items: center;
         justify-content: center;
         padding: 0.4rem;
         border-radius: 50%;
         background-color: var(--bgc-1-o);
         font-weight: 700;
      }
      .btnL {
         left: 0.5rem;
      }
      .btnR {
         right: 0.5rem;
      }
      .imgIndex {
         position: absolute;
         top: 0.5rem;
         right: 0.5rem;
         font-size: 12px;
      }
      .mpC {
         position: absolute;
         left: 10px;
         bottom: 10px;
         display: flex;
         gap: 6px;
         > a > img {
            height: 1rem;
            width: 1rem;
         }
         > div > img {
            height: 1rem;
            width: 1rem;
            filter: grayscale(1);
         }
      }
      .stage {
         position: absolute;
         bottom: 10px;
         right: 10px;
         padding: 6px 12px;
         font-size: 14px;
         font-weight: 700;
         border-radius: 20px;
         background-color: var(--main-1);
         color: var(--bgc-1);
      }
   }
   .cardPart1 {
      border-top: var(--border);
      padding: 16px;
      .price {
         font-size: 24px;
         font-weight: 700;
         margin-bottom: 8px;
      }
      .address {
         font-size: 12px;
         font-weight: 400;
         color: var(--text-1-o);
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
      }
   }
   .cardPart2 {
      display: flex;
      border-top: var(--border);
      border-bottom: var(--border);
      > div {
         flex: 1 1 0px;
         height: 40px;
         padding: 6px 0;
         display: flex;
         align-items: center;
         justify-content: center;
         font-size: 12px;
         font-weight: 400;
         &:not(:last-child) {
            border-right: var(--border);
         }
      }
   }
   .cardPart3 {
      padding: 16px;
      > li {
         > span {
            color: var(--text-1-o);
         }
      }
   }
}
