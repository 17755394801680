.contentC {
   .controlsC {
      display: flex;
      gap: 1rem;
      width: fit-content;
      margin-bottom: 1rem;
   }
   .propsC,
   .unitsC {
      background-color: var(--background-1);
      padding: 1rem;
      .headerC {
         margin-bottom: 8px;
      }
      .props,
      .units {
         display: flex;
         gap: 1rem;
         flex-wrap: wrap;
         align-items: center;
      }
   }
}
