.back,
.close {
   height: 2rem;
   width: 2rem;
   background-color: var(--text-1-o);
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
}
.back {
   &::after {
      display: block;
      width: 20%;
      height: 20%;
      content: "";
      border-left: 2px solid var(--text-1);
      border-bottom: 2px solid var(--text-1);
      transform: rotate(45deg);
   }
}
.close {
   transform: rotate(45deg);
   &::after {
      display: block;
      width: 50%;
      content: "";
      border-radius: 50%;
      border-left: 2px solid var(--text-1);
      border-bottom: 2px solid var(--text-1);
   }
   &::before {
      display: block;
      position: absolute;
      height: 50%;
      border-radius: 50%;
      content: "";
      border-left: 2px solid var(--text-1);
      border-bottom: 2px solid var(--text-1);
   }
}
.contentC {
   cursor: pointer;
   font-weight: 700;
   // height: 2.4rem;
   padding: 8px 8px;
   font-size: 12px;
   border-radius: 10px;
   border: none;
   color: var(--text-1);
   user-select: none;
}
.confirmC {
   background-color: var(--bgc-1);
   width: min(30rem, 90%);
   padding: 1.4rem;
   border-radius: 20px;
   > b {
      white-space: wrap;
      margin-bottom: 1rem;
      display: block;
   }
   text-align: center;
   .controlsC {
      display: flex;
      justify-content: space-between;
   }
}

// @types
.main {
   background-color: var(--text-1-o-10);
   &:enabled {
      color: var(--text-2);
      background-color: var(--text-1);
      &:hover {
         background-color: var(--scnd-1);
      }
   }
}

.scnd {
   background-color: var(--text-1-o-10);
   color: #1a2d3d;
   &:enabled {
      background-color: var(--main-2);
      &:hover {
         background-color: var(--scnd-1);
      }
   }
}

.warn {
   background-color: var(--text-1-o-10);
   &:enabled {
      color: white;
      background-color: var(--main-3);
      &:hover {
         background-color: red;
      }
   }
}

.bland {
   position: relative;
   background-color: transparent;
   text-decoration: underline;
   font-weight: 400;
   color: var(--scnd-1);
   &:hover {
      // font-weight: 700;
   }
   &:enabled {
   }
}
