.spinner {
   position: relative;
   height: 100%;
   aspect-ratio: 1;
   border-radius: 50%;
}

.spinner:before,
.spinner:after {
   content: "";
   position: absolute;
   border-radius: inherit;
}

.spinner:before {
   width: 100%;
   height: 100%;
   background-image: linear-gradient(0deg, #009efd 0%, #2af598 100%);
   animation: spin 1s infinite linear;
}

.spinner:after {
   width: 85%;
   height: 85%;
   background-color: var(--bgc-1);
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}

@keyframes spin {
   to {
      transform: rotate(360deg);
   }
}
