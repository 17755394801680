.contentC {
   position: relative;
   border-top: 1px solid var(--text-1-o);
   .title{
      margin-bottom: 1rem;
   }
   .relationships {
      .relationship {
         padding: 0.8rem 0.6rem;
         border: 1px solid var(--text-1);
         border-radius: 20px;
         .tableTitle {
            font-weight: 700;
            margin-bottom: 0.8rem;
         }
         .entries {
            display: flex;
            flex-wrap: wrap;
            gap: 0.4rem;
            align-items: center;
            .entry {
               font-size: 14px;
               max-width: 10rem;
               padding: 0.4rem 1rem;
               border: 1px solid var(--text-1);
               border-radius: 10px;
               .id {
                  font-weight: 700;
                  text-decoration: underline;
               }
               .info {
                  display: flex;
                  > b {
                     display: block;
                     max-width: 8rem;
                  }
                  > span {
                     white-space: nowrap;
                     overflow: hidden;
                     text-overflow: ellipsis;
                  }
               }
            }
         }
      }
   }
}
