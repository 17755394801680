.contentC {
   .contactPopup {
      background-color: var(--bgc-1);
      padding: 1rem;
      border-radius: 20px;
      .contactPopupHeaderC {
         display: flex;
         justify-content: space-between;
         gap: 1rem;
         align-items: center;
         margin-bottom: 1rem;
      }
      .contactPopupInputs {
         display: flex;
         flex-direction: column;
         gap: 1rem;
         margin-bottom: 1rem;
      }
      .contactPopupControlls {
         > button {
            display: block;
            margin: auto;
         }
      }
   }
   .postDataC {
      padding: 1rem;
      background-color: var(--bgc-1);
      border-radius: 20px;
      max-width: min(100vw, 600px);
      max-height: min(100vh, 400px);
      overflow-x: auto;
      .postDataTitle {
         display: flex;
         align-items: center;
         justify-content: space-between;
         margin-bottom: 1rem;
      }
      .postDataInfoC {
      }
   }
   .controllsC {
      padding: 0 1rem 1rem 1rem;
      margin-bottom: 1rem;
      background-color: var(--bgc-1);
      display: flex;
      justify-content: space-between;
      gap: 2rem;
      .submitting {
         display: flex;
         gap: 8px;
         align-items: center;
         margin: auto;
      }
      .controllsL {
         .controllsInfo {
            padding: 8px 1rem;
            border-radius: 10px;
            background-color: var(--body-bgc-1);
            margin: 6px 0;
            > p {
               font-size: 14px;
               color: var(--text-1-o);
            }
         }
         .controllsLU {
            display: flex;
            align-items: center;
            gap: 8px;
            > p {
               font-size: 12px;
            }
         }
         .controllsLD {
            display: flex;
            gap: 12px;
         }
      }
      .controllsR {
         display: flex;
         flex-direction: column;
         gap: 8px;
         .controllsRU,
         .controllsRD {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 12px;
         }
      }
   }
   .notesC {
      background-color: var(--main-2);
      padding: 1rem 2rem;
      margin-bottom: 1rem;
      margin-inline: auto;
      width: fit-content;
      border-radius: 20px;
      width: min(600px, 96%);
      > li {
         list-style-type: disc;
         font-size: 14px;
      }
   }
   .tableC {
      width: fit-content;
      max-width: calc(100% - 2rem);
      padding: 2px;
      margin: auto;
      overflow-x: auto;
   }
}
