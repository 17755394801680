.contentC {
   background-color: var(--bgc-1);
   padding: 1rem;
   border-radius: 20px;
   width: min(96%, 500px);
   .headerC {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
   }
   .inputsC {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: 1rem;
   }
   .errorC {
      > p {
         font-size: 12px;
         color: var(--main-3);
      }
   }
   .controlsC {
      display: flex;
      flex-direction: row-reverse;
   }
}
