.contentC {
   position: relative;
   .controls {
      display: flex;
      align-content: center;
      gap: 1rem;
      padding: 1rem 1rem;
      border-bottom: 1px solid var(--text-1);
   }
}
