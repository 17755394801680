.contentC {
   border-radius: 10px;
   background-color: var(--text-1-o-10);
   padding: 1rem;
   font-style: italic;
   &:hover {
      outline: 1px solid var(--main-1);
   }
   .eventHeaderC {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      .eventHeaderL {
         > b {
            text-transform: capitalize;
            text-decoration: underline;
         }
      }
      > div {
         display: flex;
         align-items: center;
         gap: 6px;
         > div {
            height: 8px;
            width: 8px;
            border-radius: 50%;
            background-color: var(--main-3);
         }
      }
   }
   .msgC {
      > p {
         > b {
            text-transform: capitalize;
         }
      }
   }
   .previewC {
      margin-top: 12px;
      padding: 12px;
      background-color: var(--bgc-2);
      max-height: 60px;
      overflow: hidden;
      position: relative;
      transition: 0.4s ease all;
      .expandBtn {
         position: absolute;
         left: 50%;
         transform: translateX(-50%);
         top: 32px;
      }
   }
   .expanded {
      max-height: 100vh;
      .expandBtn {
         top: unset;
         bottom: 0px;
      }
   }
}
.unseen {
   background-color: var(--main-1-o-20);
}
