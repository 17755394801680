.contentC {
   padding: 1rem;
   background-color: var(--bgc-1);
   display: flex;
   flex-direction: column;
   gap: 8px;
   border-radius: 10px;
   width: 360px;
   .fieldsC {
      display: flex;
      flex-direction: column;
      gap: 6px;
   }
   .errorC {
      font-size: 12px;
      margin: auto;
      > p {
         color: var(--main-3);
      }
   }
   .controlsC {
      display: flex;
      justify-content: space-between;
   }
}
