.contentC {
   .controlsC {
      background-color: var(--bgc-1);
      display: flex;
      padding: 30px 50px 24px 50px;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 1rem;
      .controlsLeft {
         display: flex;
         align-items: center;
         gap: 14px;
         flex-grow: 1;
         .csvUploadedC {
            > div {
               display: block;
               max-width: 8rem;
               white-space: nowrap;
               overflow: hidden;
               text-overflow: ellipsis;
            }
         }
         .uwSearchInput {
            flex-grow: 1;
            max-width: 30rem;
         }
      }
      .controlsRight {
         display: flex;
         gap: 14px;
         align-items: center;
         > a {
            color: var(--text-1);
            text-decoration: underline;
         }
      }
   }
}

@media screen and (max-width: 1000px) {
   .contentC {
      .controlsC {
         padding: 30px 1rem 24px 1rem;
         .controlsLeft,
         .controlsRight {
            flex-wrap: wrap;
            align-items: center;
         }
      }
   }
}
