.contentC {
   background-color: var(--body-bgc-1);
   height: 90%;
   width: 90%;
   border-radius: 20px;
   display: flex;
   flex-direction: column;
   .headerC {
      display: flex;
      padding: 1rem;
      justify-content: space-between;
      align-items: center;
      background-color: var(--bgc-1);
      .headerL {
         display: flex;
         gap: 1rem;
         align-items: center;
      }
      .headerR {
         display: flex;
         align-items: center;
         gap: 1rem;
      }
   }
   .bodyC {
      margin: 1rem;
      flex-grow: 1;
      position: relative;
      overflow-x: auto;
      overflow-y: auto;
      border-radius: 20px;
   }
}
