.contentC {
   max-width: 100%;
   background-color: var(--bgc-1);
   border: 1px solid var(--text-1-o-10);
   border-collapse: collapse;
   thead,
   tbody {
      tr {
         cursor: pointer;
         th,
         td {
            border: 1px solid var(--text-1-o-10);
            border-collapse: collapse;
            // max-width: 10rem;
            // min-width: 10rem;
            white-space: nowrap;
            text-overflow: ellipsis;
         }
      }
   }
   thead {
      // background-color: var(--text-1);
      tr {
         th {
            // color: white;
            font-weight: 700;
            font-size: 14px;
            padding: 8px 0px;
            &:hover {
               background-color: var(--scnd-1);
            }
         }
      }
   }
   tbody {
      max-width: 100%;
      overflow-x: overlay;
      // background-color: var(--text-1-o-10);
      tr {
         th {
            text-align: left;
            overflow-x: hidden;
            padding: 6px 6px;
            max-width: 8rem;
            font-size: 12px;
            font-weight: 400;
         }
         td {
            padding: 6px 6px;
            width: fit-content;
         }
         &:hover {
            background-color: var(--text-1-o);
         }
      }
   }
}

.internalScroll {
   thead,
   tbody {
      display: block;
      tr {
         cursor: pointer;
         th,
         td {
            overflow-x: hidden;
         }
      }
   }
}
.hightlight {
   text-decoration: underline;
}
