.contentC {
   background-color: var(--bgc-1);
   .controlsC {
      display: flex;
      padding: 30px 50px 24px 50px;
      gap: 14px;
      .addressC {
         display: flex;
         flex-direction: column;
         gap: 1rem;
         .header {
            font-weight: 700;
         }
      }
   }
}
