.contentC {
   background-color: var(--background-0);
   height: 100%;
   position: relative;
   display: flex;
   flex-direction: column;
   .headerC {
      background-color: var(--background-1);
      padding: 1rem;
      padding-bottom: 0px;
      display: flex;
      align-items: center;
      gap: 1rem;
      .header {
         > b {
            font-size: 24px;
         }
      }
   }
   .tabsC {
      padding: 0 1rem;
      background-color: var(--background-1);
   }
   .bodyC {
      padding: 1rem;
      overflow-y: auto;
      margin-bottom: 4rem;
   }
   .bottomBarC {
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0.6rem;
      // height: 60px;
      background-color: var(--secondary-1);
      display: flex;
      align-items: center;
      gap: 8px;
      overflow-x: auto;
      .bottomBarInput {
         // width: 12rem !important;
         width: fit-content;
         flex-shrink: 0;
      }
   }
}
