.contentC {
   display: flex;
   // .contentL,
   // .contentR {
   //    width: 50%;
   // }
   .contentL {
      flex-grow: 1;
      padding: 1rem 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: calc(100vw - 30rem);
      .tableName {
         text-transform: uppercase;
         margin-bottom: 2rem;
      }
      .tableNav {
         padding: 1rem;
         display: flex;
         gap: 1rem;
         align-items: center;
      }
      .tableC {
         width: 100%;
         // height: 30rem;
         overflow-x: overlay;
         // display: flex;
         // justify-content: center;
         > * {
            margin: auto;
         }
      }
   }
   .contentR {
      border-left: 1px solid var(--text-1);
      width: 30rem;
      .controls {
         > :not(:last-child) {
            margin-bottom: 1rem;
         }
         padding: 1rem;
         .inputs {
            display: flex;
         }
      }
   }
}
