.contentC {
    > :not(:last-child) {
   margin-bottom: 2rem;
   }
   .bulkC{
      border: 1px solid var(--text-1);
      padding: 1rem;
      border-radius: 20px;
      .title {
         margin-bottom: 1rem;
         display: flex;
         align-items: center;
         gap: 1rem;
      }
      .bulkGroups {
         .bulkGroup {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            margin-bottom: 2rem;
         }
      }
   }
   .fieldsC {
      border: 1px solid var(--text-1);
      padding: 1rem;
      border-radius: 20px;
      .title {
         margin-bottom: 1rem;
         display: flex;
         align-items: center;
         gap: 1rem;
      }
      .fields {
         display: flex;
         flex-wrap: wrap;
         gap: 1rem;
      }
   }
   .tableC{ 
      padding: 1rem;
      max-width: 100%;
      overflow-x: overlay;
      .title {
         margin-bottom: 1rem;
      }

   }
}

.sim {
   .groups {
      background-color: green;
      display: flex;
      flex-wrap: wrap;
      > div {
         width: 12rem;
      }
   }
   .units {
      .unit {
         display: flex;
         flex-wrap: wrap;
         background-color: blue;
         > div {
            width: 16rem;
         }
      }
   }
}
