.contentC {
   font-size: 14px;
   display: inline-block;
   padding: 0.6rem 1rem;
   position: relative;
   cursor: pointer;
}
.activeView {
   font-weight: 600;
      &::after {
         content: "";
         display: block;
         position: absolute;
         bottom: 0;
         left: 0;
         background-color: var(--main-1);
         border-radius: 10px 10px 0 0;
         border: 1px solid var(--main-1);
         width: 100%;
         height: 1px;
      }
}
