.contentC {
   .headerC {
      background-color: var(--bgc-1);
      padding: 0 2rem 1rem 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .headerR {
         display: flex;
         gap: 1rem;
      }
   }
   .notesC {
      background-color: var(--main-2);
      padding: 1rem 2rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      margin-inline: auto;
      width: fit-content;
      border-radius: 20px;
      width: min(600px, 96%);
      > li {
         list-style-type: disc;
         font-size: 14px;
      }
   }
   .configC {
      width: min(600px, 96%);
      padding: 1rem;
      background-color: var(--bgc-1);
      border-radius: 10px;
      margin-inline: auto;
      margin-bottom: 1rem;
      .config {
         margin-top: 10px;
         display: flex;
         gap: 8px;
         flex-wrap: wrap;
         .configExpand {
            flex-grow: 1;
         }
      }
   }
   .ss {
      // min-width: 100%;
      width: fit-content;
      display: block;
      margin: auto;
      max-width: 100%;
      overflow-x: auto;
      position: relative;
      background-color: var(--bgc-1);
      font-size: 12px;
   }
   .footerControlls {
      margin-top: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
   }
}
.warn {
   border: 2px solid red !important;
}
