.contentC {
   width: 280px;
   border-radius: 20px;
   overflow: hidden;
   background-color: var(--background-1);
   .p2C {
      padding: 1rem;
      > b {
         font-size: 22px;
         // display: block;
         // margin-bottom: 8px;
      }
      > p {
         margin-top: 4px;
         color: var(--text-0-o-50);
         font-size: 12px;
      }
   }
   .p3C {
      --border: 1px solid var(--text-0-o-10);
      border-top: var(--border);
      border-bottom: var(--border);
      height: 46px;
      display: flex;
      > div {
         &:not(:last-child) {
            border-right: var(--border);
         }
         flex-grow: 1;
         flex-basis: 0;
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         > p {
            font-size: 12px;
            color: var(--text-0-o-50);
         }
         > b {
            font-size: 14px;
            color: var(--text-0);
         }
      }
   }
   .p4C {
      padding: 1rem;
      > div {
         > span {
            font-size: 14px;
            color: var(--text-0-o-50);
         }
         > b {
            font-size: 14px;
            color: var(--text-0);
         }
      }
   }
}
