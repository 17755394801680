.header-m {
   font-weight: 700;
   font-size: 30px;
}
.header-s {
   font-weight: 700;
   font-size: 20px;
}
.padding-s {
   padding: 1rem;
}
.ellipsis {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}
.underline {
   text-decoration: underline;
}

.no-select {
   user-select: none;
}
.hidden {
   display: none;
}

.cardHeader-s {
   color: var(--text-1-o);
   font-size: 14px;
   font-weight: 700;
   letter-spacing: 1.12px;
   text-transform: uppercase;
}
