.contentC {
   background-color: var(--background-0);
   width: min(90%, 500px);
   .headerC {
      padding: 1rem;
      background-color: var(--background-1);
   }
   .bodyC {
      padding: 1rem;
      display: flex;
      gap: 1rem;
      align-items: center;
   }
}
