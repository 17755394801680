.contentC {
   .viewsC {
      background-color: var(--background-1);
      padding: 0 8px 8px 8px;
      border-radius: 10px;
      .viewTabs {
      }
      .levelsC {
         background-color: var(--background-0);
         padding: 8px;
         border-radius: 10px;
         .levels {
            background-color: var(--background-1);
            padding: 0 8px 8px 8px;
            border-radius: 10px;

            .levelTabs {
               border-radius: 10px;
               padding: 0 8px;
            }
            .groupsC {
               background-color: var(--background-0);
               padding: 8px;
               border-radius: 10px;
               .groups {
                  background-color: var(--background-1);
                  padding: 0 8px 8px 8px;
                  border-radius: 10px;
                  .group {
                     padding: 1rem 0;
                     background-color: var(--background-0);
                     border-radius: 10px;
                     .group1 {
                        padding: 0 1rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 8px;
                     }
                  }
               }
            }
         }
      }
   }
}
