.contentC {
   width: 1rem;
   height: 1rem;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #fff;
   border-radius: 50%;
   position: relative;
   .dot {
      border-radius: 50%;
      height: 0.8rem;
      width: 0.8rem;
   }
   .hoverC {
      z-index: 1;
      position: absolute;
      display: none;
      bottom: calc(100% + 1rem);
      left: 50%;
      transform: scale(0.5) translateX(-50%);
      transform-origin: 0% 100%;
   }
   &:hover {
      background-color: var(--text-1);
      outline: 2px solid var(--text-1);
      .hoverC {
         display: block;
      }
   }
}
