.contentC {
   padding: 1rem;
   background-color: var(--bgc-1);
   border-radius: 20px;
   max-height: 20rem;
   overflow-y: overlay;
   display: flex;
   gap: 1rem;
   .controls {
      display: flex;
      flex-direction: column;
      gap: 1rem;
   }
}
