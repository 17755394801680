.contentC {
   height: min(80%, 600px);
   // background-color: red;
   width: min(90%, 600px);
   position: relative;
   z-index: 100000;
   .scrollC {
      margin-top: 1rem;
      border-radius: 20px;
      background-color: var(--body-bgc-1);
      overflow: hidden;
      > ul {
         max-height: 400px;
         overflow-y: auto;
         padding: 12px;
         display: flex;
         flex-direction: column;
         gap: 8px;
         > li {
            padding: 8px;
            background-color: var(--bgc-1);
            border-radius: 10px;
            > div {
               margin-bottom: 6px;
            }
            > ul {
               display: flex;
               flex-direction: column;
               gap: 6px;
               > li {
                  background-color: var(--body-bgc-1);
                  padding: 6px;
                  border-radius: 8px;
                  font-size: 12px;
                  font-weight: 700;
                  cursor: pointer;
                  scroll-margin: 250px;
               }
               .highlighted {
                  background-color: var(--main-1-o);
               }
            }
         }
      }
   }
}
