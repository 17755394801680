.contentC {
   background-color: var(--background-0);
   border-radius: 20px;
   width: min(600px, 96%);
   .headerC {
      background-color: var(--background-1);
      border-radius: 20px 20px 0 0;
      padding: 0.6rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
   }
   .bodyC {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      .bodyRow {
         display: flex;
         align-items: center;
         gap: 1rem;
      }
      .infoC {
         .propC {
            margin-bottom: 8px;
         }
         .unitsC {
            .unitsH {
               display: flex;
               gap: 1rem;
               align-items: center;
            }
            .units {
               display: flex;
               width: 100%;
               overflow-x: auto;
               padding-bottom: 8px;
               .unit {
                  padding: 0 8px;
                  &:not(:last-child) {
                     border-right: 1px solid var(--text-0-o-10);
                  }
                  .unitH {
                     margin-bottom: 8px;
                  }
                  .unitInputs {
                     display: flex;
                     flex-direction: column;
                     gap: 8px;
                     width: 12rem;
                  }
               }
            }
         }
      }
   }
   .controlsC {
      padding: 0.6rem 1rem;
      background-color: var(--background-1);
      border-radius: 0 0 20px 20px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      .controlsL {
         display: flex;
         gap: 1rem;
      }
   }
}
