.contentC {
   background-color: var(--body-bgc-1);
   .headerC {
      padding: 1rem 1rem 0 1rem;
      background-color: var(--bgc-1);
   }
   .tabsC {
      padding: 0 1rem;
      background-color: var(--bgc-1);
   }
}
