.contentC {
   .headerC {
      display: flex;
      justify-content: space-between;
      padding: 1rem;
      background-color: var(--background-1);
      .headerL {
         display: flex;
         align-items: center;
         gap: 1rem;
         .headerL1 {
            > p {
               > span {
                  font-size: 14px;
                  color: var(--text-0-o-50);
               }
            }
         }
      }
   }
   .bodyC {
      .detailsC {
         padding-top: 1rem;
         border-top: 1px solid var(--text-0-o-10);
         margin-bottom: 1rem;
         > h1 {
            // display: block;
            text-align: center;
            margin-bottom: 1rem;
         }
         .details {
            display: flex;
            gap: 1rem;
            flex-direction: column;
            align-items: center;
         }
      }
      .propsC {
         padding-top: 1rem;
         border-top: 1px solid var(--text-0-o-10);
         > h1 {
            // display: block;
            text-align: center;
            margin-bottom: 1rem;
         }
         .props {
            display: flex;
            gap: 1rem;
            justify-content: center;
            flex-wrap: wrap;
         }
      }
   }
}
.uw {
   background-color: var(--background-1);
   border-radius: 20px;
   width: 320px;
   .uw1 {
      padding: 14px;
      > div {
         margin-bottom: 8px;
         display: flex;
         justify-content: space-between;
         align-items: center;
         > b {
            font-size: 22px;
         }
      }
      > p {
         font-size: 14px;
         color: var(--text-0-o-50);
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
      }
   }
   .uw2 {
      --border: 1px solid var(--text-0-o-10);
      border-top: var(--border);
      border-bottom: var(--border);
      display: flex;
      > div {
         flex-grow: 1;
         flex-basis: 0;
         display: flex;
         flex-direction: column;
         align-items: center;
         padding: 4px 0;
         > p {
            font-size: 12px;
            color: var(--text-0-o-50);
         }
         > b {
            font-size: 14px;
         }
         &:not(:last-child) {
            border-right: var(--border);
         }
      }
   }
   .uw3 {
      padding: 14px;
      > div {
         margin-bottom: 6px;
         > p {
            font-size: 14px;
            color: var(--text-0-o-50);
         }
         > b {
            font-size: 14px;
         }
      }
   }
}
