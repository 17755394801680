.contentC {
   padding: 1rem;
   .headerC {
      display: flex;
      gap: 1rem;
      margin-bottom: 2rem;
   }
   .groupsC {
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
      .group {
         padding: 0.6rem;
         border: 1px solid var(--text-1-o);
         width: 16rem;
         .groupHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .title {
               font-weight: 700;
            }
            .icons {
               display: flex;
               gap: 0.2rem;
               img {
                  width: 16px;
                  height: 16px;
                  cursor: pointer;
                  &:hover {
                     outline: 1px solid black;
                  }
               }
            }
         }
      }
   }
}
