.contentC {
   display: flex;
   flex-direction: column;
   gap: 1rem;
   justify-content: center;
   align-items: center;
   height: calc(50vh - 4rem);
   padding-top: 10rem;
   > a {
      > button {
         width: 20rem;
      }
   }
}
