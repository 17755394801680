.contentC {
   .headerC{
      background-color: var(--background-1);
      padding: 1rem;
     display: flex; 
     align-items: center;
     gap: 1rem;
     .header{
      > ul{
         display: flex;
         gap: 6px;
         flex-wrap: wrap;
         > li {
            font-size: 12px;
            font-weight: 700;
            color: var(--text-0-o-50);
            font-style: italic;
            cursor: pointer;
            &:hover {
               color: var(--secondary-1);
            }
         }
         .currStack {
            text-decoration: underline;
            color: var(--text-0);
         }
      }
     }
   }
   .bodyC {
      padding: 1rem;
      .cardC {
         padding: 12px;
         background-color: var(--background-1);
         border-radius: 12px;
         margin-bottom: 1rem;
         >h1 {
            margin-bottom: 8px;
         }
      }
      .infoC{

         .info {
            display: flex;
            .infoL {
               padding: 0 8px;
               border-right: 1px solid var(--text-0-o-10);
            }
            .infoR {
               padding: 0 8px;

            }
         }
         
      }
      .fxC{
         >pre {
            background-color: var(--text-0);
            padding: 8px;
            width: 100%;
            overflow-x: auto;
            >code {
               color: var(--background-1);
            }
         }
      }
      .depsC {
         >ul {
            padding: 8px;
            background-color: var(--background-0);
            display: flex;
            flex-direction: column;
            gap: 8px;
         }
      }
   }
}