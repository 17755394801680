.contentC {
   .headerC {
      padding: 1rem;
      background-color: var(--background-1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      .headerL {
         display: flex;
         gap: 1rem;
         align-items: center;
      }
   }
   .bodyC {
      .SSC {
         .SSConfig {
            padding: 1rem;
            display: flex;
            flex-direction: column;
            margin: auto;
            width: min(500px, 96%);
            gap: 1rem;
         }
         .SS {
            overflow-x: auto;
         }
      }
   }
}
