.contentC {
   .headerC {
      padding: 1rem 2rem 1rem 2rem;
      background-color: var(--bgc-1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      .headerM {
         flex-grow: 1;
         max-width: 40%;
      }
      .headerR {
         display: flex;
         gap: 1rem;
      }
   }
   .uwsC {
      .uwsHeaderC {
         padding: 1rem;
      }
      .uws{
         display: flex;
         gap: 1rem;
         flex-wrap: wrap;
         justify-content: center;
      }
   }
}
