.contentC {
   background-color: var(--bgc-1);
   padding: 1rem;
   border-radius: 10px;
    > :not(:last-child) {
   margin-bottom: 16px;
   }
   .search {
      display: flex;
   }
}