@font-face {
   font-family: GT Walsheim;
   src: url(https://dyaj6jwzih5dm.cloudfront.net/fonts/GT-Walsheim-Regular.otf);
}
@font-face {
   font-family: GT Walsheim;
   font-weight: bold;
   src: url(https://dyaj6jwzih5dm.cloudfront.net/fonts/GT-Walsheim-Bold.otf);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
// input[type=number] {
//   -moz-appearance: textfield;
// }

input {
   border: none;
   background-color: transparent;
}
input:focus {
   outline: none;
}
.tr {
   outline: 1px solid red;
}
.noSelect {
   user-select: none;
}
a {
   text-decoration: none;
   color: black;
   width: fit-content;
}
li {
   list-style-type: none;
}
* {
   padding: 0;
   margin: 0;
   // min-width: 0;
   box-sizing: border-box;
   color: var(--text-1);
   transition: background-color 0.4s ease;
   transition: color 0.4s ease;
}
input,
select,
textarea,
button {
   border: none;
   outline: none;
   background-color: transparent;
   font-family: inherit;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}
button {
   cursor: pointer;
}
body {
   font-family: GT Walsheim;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.react-resizable {
   position: relative;
   /* padding-right: 20px; */
}

.react-resizable-handle {
   z-index: 99;
   position: absolute;
   padding: 0 !important;
   width: 20px !important;
   height: 36px !important;
   // top: 50%;
   // right: unset !important;
   // left: 0 !important;
   transform: translate(-50%, -50%) !important;
   background-image: url("https://dyaj6jwzih5dm.cloudfront.net/marketplace/common/resizeSlider.svg") !important;
   cursor: ew-resize;
}

:root {
   --body-bgc-1: #f6f9fc;

   --main-1: #9bd0b0;
   --main-1-o: hsla(144, 36%, 71%, 0.5);
   --main-1-o-20: hsla(144, 36%, 71%, 0.2);
   --main-1-filter: invert(72%) sepia(43%) saturate(197%) hue-rotate(91deg) brightness(100%) contrast(89%);
   --main-2: #e0f1ff;
   --main-2-o: hsla(207, 100%, 94%, 0.5);
   --main-3: #e72844;
   --main-4: #ffe39b;
   --text-1: #1a2d3d;
   --text-1-o: hsla(207, 40%, 17%, 0.5);
   --text-1-o-80: hsla(207, 40%, 17%, 0.8);
   --text-1-o-10: hsla(207, 40%, 17%, 0.1);
   --text-1-filter: brightness(0) saturate(100%) invert(15%) sepia(18%) saturate(1377%) hue-rotate(166deg)
      brightness(91%) contrast(93%);

   --text-2: #fff;
   --text-2-o: hsla(0, 0%, 100%, 0.5);

   --scnd-1: #385b79;
   --scnd-1-o: hsla(208, 37%, 35%, 0.5);
   --scnd-1-o-10: hsla(208, 37%, 35%, 0.1);
   --scnd-1-o-30: hsla(208, 37%, 35%, 0.3);
   --scnd-1-filter: invert(32%) sepia(18%) saturate(1270%) hue-rotate(166deg) brightness(92%) contrast(85%);
   --scnd-2: #ffecbf;
   --scnd-2-o: hsla(42, 100%, 87%, 0.5);
   --bgc-1: #fff;
   --bgc-1-o: hsla(0, 0%, 100%, 0.5);
   --bgc-1-filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(23deg) brightness(103%) contrast(102%);
   --bgc-2: #f8f8f8;
   --illu-1: #8fbfe9;
   --illu-1-o-40: hsla(208, 67%, 74%, 0.4);
   --illu-2: #e5a4a3;
   --custom-1: #d98685;
   --custom-1-filter: invert(70%) sepia(35%) saturate(669%) hue-rotate(311deg) brightness(84%) contrast(103%);
}

[data-theme="dark"] {
   --body-bgc-1: #131313;

   // --main-1: #642f4f;
   // --main-1-o: hsla(324, 36%, 29%, 0.5);
   // --main-1-o-20: hsla(324, 36%, 29%, 0.2);
   // --main-1-filter: invert(21%) sepia(11%) saturate(3002%) hue-rotate(271deg) brightness(95%) contrast(88%);
   /* --main-2: #e0f1ff; */
   /* --main-2-o: hsla(207, 100%, 94%, 0.5); */
   /* --main-3: #ff5962; */
   /* --main-4: #ffe39b; */
   --text-1: #fff;
   --text-1-o: hsla(0, 0%, 100%, 0.5);
   --text-1-o-10: hsla(0, 0%, 100%, 0.1);
   --text-1-o-80: hsla(0, 0%, 100%, 0.8);
   --text-1-filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(248deg) brightness(106%) contrast(106%);

   --text-2: #1a2d3d;
   --text-2-o: hsla(207, 40%, 17%, 0.5);

   --scnd-1: #929394;
   /* --scnd-1-o: hsla(208, 37%, 35%, 0.5); */
   /* --scnd-1-o-10: hsla(208, 37%, 35%, 0.1); */
   /* --scnd-1-o-30: hsla(208, 37%, 35%, 0.3); */
   /* --scnd-1-filter: invert(32%) sepia(18%) saturate(1270%) hue-rotate(166deg) brightness(92%) contrast(85%); */
   --scnd-2: #001340;
   --scnd-2-o: hsla(222, 100%, 13%, 0.5);
   --bgc-1: #1a1c1e;
   --bgc-1-filter: invert(7%) sepia(4%) saturate(1725%) hue-rotate(169deg) brightness(90%) contrast(90%);
   --bgc-2: #262829;
   /* --illu-1: #8fbfe9; */
   /* --illu-1-o-40: hsla(208, 67%, 74%, 0.4); */
   /* --illu-2: #e5a4a3; */
   /* --custom-1: #d98685; */
   /* --custom-1-filter: invert(70%) sepia(35%) saturate(669%) hue-rotate(311deg) brightness(84%) contrast(103%); */
}
