.contentC {
   display: flex;
   flex-direction: column;
   .headerC {
      padding: 16px;
      background-color: var(--bgc-1);
      .searchInput {
         width: min(100%, 200px);
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
   }
   .bodyC {
      display: flex;
      flex-grow: 1;
      height: calc(100vh - 74px);
      .assetsC {
         max-height: 100%;
         overflow-x: auto;
         flex-grow: 1;
         padding: 16px;
         .assetsHeaderC {
            margin-bottom: 1rem;
            display: flex;
            justify-content: space-between;
            .assetsHeaderL {
               > p {
                  margin-bottom: 8px;
               }
            }
            .assetsHeaderR {
               .assetsHeaderR1 {
                  display: flex;
                  gap: 8px;
                  margin-bottom: 8px;
                  align-items: center;
               }
               .assetsHeaderR2 {
                  align-items: center;
                  display: flex;
                  gap: 8px;
               }
               .assetsHeaderR3{
                  display: flex;
                  justify-content: flex-end;
               }
            }
         }
         .assets{
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            justify-content: center;
            .tableC {
               padding: 2px 1rem;
               width: 100%;
               overflow-x: auto;
            }
         }
      }
      .resizeBox {
         height: 100% !important;
         flex-shrink: 0;
         max-width: calc(100vw - 360px);
         // position: relative;
         .hlAsset {
            position: absolute;
            background-origin: 1rem;
            left: 8px;
            bottom: 8px;
            transform: scale(50%);
            transform-origin: 0% 100%;
         }
      }
   }
}
