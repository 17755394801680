.contentC {
   height: 100%;
   background-color: var(--body-bgc-1);
   display: flex;
   flex-direction: column;
   .headerC{
      padding: 1rem;
      display: flex;
      align-items: center;
      background-color: var(--bgc-1);
      gap: 1rem;
   }
   .filtersC {
      flex-grow: 1;
      margin: 1rem;
      padding: 8px;
      background-color: var(--bgc-1);
      max-width: 100%;
      overflow-x: auto;
      display: flex;
      flex-direction: column;
      gap: 8px;
      >li {
         display: flex;
         flex-direction: column;
         gap: 8px;
         .filters {
            padding: 8px;
            border: 1px solid var(--text-1);
            width: fit-content;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            > li {
               display: flex;
               flex-wrap: wrap;
            }
            > li:not(:last-child) {
               border: 1px solid var(--text-1);
               border-radius: 8px;
               padding: 4px;
               display: flex;
               align-items: center;
               gap: 1rem;
            }
            .bad:not(:last-child) {
               border: 1px solid red ;
            }
            > li:last-child {
               display: flex;
               gap: 8px;
            }
         }
      }
   }
}
.actionBtn {
   width: fit-content;
}