.contentC {
   width: 16px;
   .tooltip {
      width: 100%;
      position: absolute;
      border: 1px solid orange;
      background-color: orange;
      padding: 6px 10px;
      border-radius: 6px;
      font-size: 12px;
      z-index: 2;
      left: 50%;
      top: 100%;
      transform: translateX(-50%);
      animation: 0.4s ease fadeIn forwards;
      text-wrap: balance;
      white-space: pre-line;
   }
   .img {
      width: 16px;
      filter: var(--scnd-1-filter);
   }
}

@keyframes fadeIn {
   from {
      opacity: 0;
   }
   to {
      opacity: 1;
   }
}
