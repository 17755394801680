.contentC {
   margin-top: 2rem;
   display: flex;
   flex-direction: column;
   align-items: center;
   .noteSubsC {
      width: min(90%, 600px);
      border-radius: 20px;
      overflow: hidden;
      background-color: var(--bgc-1);
      padding-bottom: 12px;
      > h1 {
         padding: 1rem 1rem 0 1rem;
      }
      .noteSubsTabsC {
         padding: 0 1rem;
      }
      .noteSubs {
         width: calc(100% - 20px);
         margin: auto;
         background-color: var(--body-bgc-1);
         outline: 1px solid var(--text-1-o-10);
         padding: 10px;
         .noteSubAddC {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 8px;
            > :first-child {
               flex-grow: 1;
            }
         }
         > ul {
            margin-top: 8px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            height: 400px;
            overflow-y: auto;
            padding: 1px;
            > li {
               display: flex;
               align-items: center;
               justify-content: space-between;
               gap: 8px;
               background-color: var(--bgc-1);
               padding: 4px 12px;
               border-radius: 10px;
               .address {
                  font-size: 12px;
                  line-height: 30px;
               }
               > button {
                  display: none;
               }
               &:hover {
                  outline: 1px solid var(--main-1-o);
                  > button {
                     display: block;
                  }
               }
            }
         }
      }
   }
}
