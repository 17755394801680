.contentC {
   background-color: var(--body-bgc-1);
   height: 100%;

   .headerC {
      padding: 0 1rem;
      height: 64px;
      display: flex;
      align-items: center;
      gap: 1rem;
      background-color: var(--bgc-1);
   }
   .notesC {
      height: calc(100% - 64px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .notesTop {
         flex-grow: 1;
         overflow-y: auto;
         padding: 2rem 1rem;
         display: flex;
         flex-direction: column-reverse;
         gap: 2rem;
         > *:not(:first-child) {
            &::before {
               content: "";
               position: absolute;
               display: block;
               bottom: -1rem;
               border-top: 1px solid var(--text-1-o);
               width: 100%;
            }
         }
      }
      .notesBot {
         background-color: var(--bgc-1);
         display: flex;
         flex-direction: row-reverse;
         align-items: center;
         gap: 1rem;
         padding: 1rem;
      }
   }
}
.mention {
   color: var(--text-1);
   text-decoration: underline;
   position: relative;
   z-index: 1;
   text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white;
}
