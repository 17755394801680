.contentC {
   .headerC {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: var(--bgc-1);
      padding: 0 2rem 1rem 2rem;
      .headerL {
         > p {
            margin-bottom: 12px;
         }
      }
   }
   .bulksC {
      padding: 1rem;
      .bulksHeader {
         margin-bottom: 1rem;
      }
      .bulks {
         display: flex;
         flex-wrap: wrap;
         gap: 1.4rem;
         justify-content: center;
         > li {
            transition: 0.4s ease all;
            width: 250px;
            background-color: var(--bgc-1);
            border-radius: 20px;
            position: relative;
            &:hover {
               outline: 3px solid var(--main-1);
               .bulkControls {
                  opacity: 1;
               }
            }
            .bulkControls {
               position: absolute;
               transition: 0.4s ease all;
               opacity: 0;
               top: -12px;
               right: 1rem;
               > button {
                  height: 24px;
                  padding: 0 8px;
               }
            }
            > a {
               .statusC {
                  padding: 6px;
                  border-radius: 10px;
                  position: absolute;
                  top: 0;
                  left: 1rem;
                  transform: translateY(-50%);
                  font-size: 10px;
                  font-weight: 700;
               }
               .done {
                  background-color: var(--main-1);
               }
               .processing {
                  background-color: var(--main-3);
               }
               .cardTop {
                  padding: 1rem 1rem 10px 1rem;
                  border-bottom: 1px solid var(--text-1-o-10);
                  > b {
                     text-decoration: underline;
                  }
                  > p {
                     line-height: 14px;
                     height: 28px;
                     font-size: 12px;
                     color: var(--text-1-o);
                     overflow: hidden;
                     text-overflow: ellipsis;
                     display: -webkit-box;
                     -webkit-line-clamp: 2;
                     -webkit-box-orient: vertical;
                  }
               }
               .cardMid {
                  font-size: 12px;
                  height: 40px;
                  display: flex;
                  border-bottom: 1px solid var(--text-1-o-10);
                  > p {
                     flex-grow: 1;
                     display: flex;
                     flex-direction: column;
                     align-items: center;
                     justify-content: center;
                     > span {
                        color: var(--text-1-o);
                     }
                     &:not(:last-child) {
                        border-right: 1px solid var(--text-1-o-10);
                     }
                  }
               }
               .cardBot {
                  padding: 10px 1rem 1rem 1rem;
                  > p {
                     font-size: 12px;
                     text-overflow: ellipsis;
                     white-space: nowrap;
                     overflow: hidden;
                     > span {
                        color: var(--text-1-o);
                     }
                  }
               }
            }
         }
      }
   }
   .emptyC {
      margin: auto;
   }
}
