.contentC {
   .mapC {
      width: 100%;
      height: 100%;
      position: relative;
      .searchInput {
         position: absolute;
         width: 50%;
         top: 12px;
         left: 50%;
         transform: translateX(-50%);
      }
      .map {
         width: 100%;
         height: 100%;
         .marker {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            border: 1.5px solid black;
            position: relative;
            cursor: pointer;
         }
         .rejected {
            background-color: red;
         }
         .owned {
            background-color: rgb(17, 224, 17);
         }
         .contracted {
            background-color: yellow;
         }
         .highlighted {
            outline: 2px solid black;
            border-radius: 0;
         }
         .markerAddress {
            position: absolute;
            font-size: 10px;
            white-space: nowrap;
            top: calc(100% + 5px);
            left: 50%;
            transform: translateX(-50%);
            background-color: white;
            padding: 0 2px;
            border: 1px solid black;
         }
      }
   }
}
