.contentC {
   padding: 0.2rem 0.4rem;
   border: 1px solid var(--text-1);
   border-radius: 10px;
   width: fit-content;
   cursor: grab;
   .type {
      font-size: 10px;
   }
   .name{
      font-weight: 700;
   }
}