.contentC {
   background-color: var(--body-bgc-1);
   padding-bottom: 50vh;
   .differences {
      padding: 1rem 2rem;
      background-color: var(--bgc-1);
      border-radius: 10px;
      max-height: 30rem;
      overflow-y: auto;
   }
   .header {
      background-color: var(--bgc-1);
      padding: 0 30px 24px 30px;
      display: flex;
      gap: 4rem;
      justify-content: space-between;
      // align-items: center;
      .infoC {
         display: flex;
         flex-wrap: wrap;
         gap: 40px;
         white-space: nowrap;
         > div {
            > p:first-child {
               color: var(--text-1-o);
            }
            > p:last-child {
               > a {
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  text-decoration: underline;
                  > img {
                     filter: var(--main-1-filter);
                  }
               }
            }
         }
      }
      .controlsC {
         > :not(:last-child) {
            margin-bottom: 1rem;
         }
         > div {
            display: flex;
            gap: 14px;
         }
      }
   }
   .ud {
      padding: 1rem;
      .udHeader {
         display: flex;
         align-items: center;
         gap: 1rem;
         margin-bottom: 1rem;
      }
      .udChildren {
         display: flex;
         flex-direction: column;
         gap: 1rem;
         .udChild {
            border: 1px solid var(--text-1-o);
            border-radius: 14px;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .udChildTitle {
               font-weight: 700;
               font-size: 18px;
               display: flex;
               align-items: center;
               gap: 1rem;
            }
         }
      }
   }
   .pd {
      padding: 1rem;
      .pdHeader {
         display: flex;
         gap: 1rem;
         margin-bottom: 1rem;
      }
   }
   .Groups {
      background-color: var(--body-bgc-1);
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      margin-bottom: 1rem;
      .Group {
         border-radius: 14px;
         background-color: var(--bgc-1);
         padding: 16px 20px;
         width: fit-content;
         .GroupTitle {
            display: flex;
            align-items: center;
            font-size: 18px;
            margin-bottom: 1rem;
         }
         .titlesC {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
         }
      }
   }

   .rows {
      padding: 1rem;
      width: 100%;
      overflow: auto;
      direction: ltr;
      > :not(:last-child) {
         margin-bottom: 0.5rem;
      }
      .row {
         display: flex;
         gap: 0.4rem;
         .unit {
            &:not(:last-child) {
               border-right: 1px solid var(--scnd-1);
            }
            padding: 0.4rem;
            > :not(:last-child) {
               margin-bottom: 0.5rem;
            }
            .unitControls {
               display: flex;
               align-items: center;
               justify-content: space-between;
            }
         }
      }
   }
}
