.contentC {
   background-color: var(--body-bgc-1);
   min-height: 100vh;

   .inspectorResizeBox {
      height: 100vh !important;
   }
   .iPopupC {
      width: min(100vw, 500px);
      padding: 1rem;
      border-radius: 10px;
      background-color: var(--bgc-1);
      .iPopupHeader {
         display: flex;
         align-items: center;
         justify-content: space-between;
         gap: 1rem;
         margin-bottom: 1rem;
      }
      .iPopupHeaderInfo {
         display: flex;
         flex-direction: column;
         gap: 8px;
      }
   }
   .infoC {
      display: flex;
      justify-content: space-between;
      background-color: var(--bgc-1);
      padding: 1px 1rem;
      .infoL {
         display: flex;
         gap: 1rem;
         overflow-x: auto;
         white-space: nowrap;
         > div,
         a {
            > p {
               color: var(--text-1-o);
               font-size: 12px;
               display: flex;
               align-items: center;
               gap: 4px;
               > span {
                  color: var(--text-1-o);
               }
               > img {
                  height: 12px;
                  filter: var(--main-1-filter);
               }
            }
            > img {
               height: 12px;
               filter: var(--text-1-filter);
            }
            > b {
               font-size: 12px;
            }
         }
      }
   }
   .viewsC {
      padding: 0rem 1rem;
      background-color: var(--bgc-1);
      box-shadow: 0px 10px 5px 0px rgba(59, 60, 68, 0.07);
      > span {
         font-size: 12px;
         text-decoration: underline;
         margin-right: 8px;
      }
   }
   .detailsC,
   .overviewBodyC {
      min-height: 100vh;
   }
   .detailsC {
      .pd {
         padding: 1rem;
         .pdHeader {
            display: flex;
            gap: 1rem;
            margin-bottom: 1rem;
            align-items: center;
         }
      }
      .ud {
         padding: 1rem;
         .udHeader {
            display: flex;
            align-items: center;
            gap: 1rem;
            margin-bottom: 1rem;
         }
         .udChildren {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            justify-content: center;
            .udChild {
               border: 1px solid var(--text-1-o);
               border-radius: 14px;
               padding: 1rem;
               display: flex;
               flex-direction: column;
               gap: 1rem;
               width: fit-content;

               .udChildTitle {
                  font-weight: 700;
                  font-size: 18px;
                  display: flex;
                  align-items: center;
                  gap: 1rem;
               }
            }
         }
      }
   }
   .overviewC {
      padding-top: 8px;
      .overviewBodyC {
         padding: 1rem 2rem;
         display: flex;
         gap: 6px;
         justify-content: center;
         .overviewBodyL {
            flex-grow: 1;
            // max-width: 30rem;
            min-width: 20rem;
            height: fit-content;
            background-color: var(--bgc-1);
            border-radius: 10px;
            padding: 4px;
            .listGroups {
               .listGroup {
                  display: flex;
                  flex-direction: column;
                  .listGroupTitle {
                     font-size: 14px;
                     border-bottom: 1px solid var(--text-1);
                     margin-bottom: 2px;
                  }
                  .listChildren {
                     margin-bottom: 8px;
                     .listChild {
                        margin-left: 8px;
                        height: 24px;
                        > span {
                           margin-left: 4px;
                           font-size: 12px;
                           white-space: nowrap;
                           overflow: hidden;
                           text-overflow: ellipsis;
                        }
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        &:last-child {
                           border-top: 2px solid black;
                           background-color: #add8e687;
                           font-weight: 600;
                           div div div div input {
                              font-weight: 600;
                           }
                        }
                        &:not(:last-child) {
                           border-bottom: 1px solid var(--text-1-o-10);
                        }
                        &:hover {
                           background-color: var(--main-1-o-20);
                        }
                     }
                  }
               }
            }
         }
         .overviewBodyR {
            // flex-grow: 1;
            width: calc(100% - 1.6rem - 6px - 18.4rem);
            max-width: 820px;
            > .Groups {
               overflow-x: auto;
               // flex-wrap: nowrap;
               flex-direction: row;
            }
         }
      }
      .overviewUnits {
         width: fit-content;
         margin: auto;
         max-width: 98%;
         overflow-x: auto;
         padding: 0 8px;
         .ovUTitle {
            background-color: var(--bgc-1);
            width: fit-content;
            border-radius: 10px 10px 0 0;
         }
         // HERE
         .Groups {
            justify-content: flex-start;
            flex-wrap: wrap;
            overflow-x: auto;

            .Group {
               width: 45%;
            }

            .typeGroup {
               flex-direction: unset;

               div div div {
                  max-width: unset;
               }
            }
         }
      }
   }

   .Groups {
      background-color: var(--body-bgc-1);
      display: flex;
      flex-wrap: wrap;
      gap: 0.4rem;
      margin-bottom: 1rem;
      justify-content: center;
      width: fit-content;
      max-width: 100%;
      // overflow-x: scroll;
      // overflow-y: visible;
      .Group {
         max-width: 100%;
         // overflow-x: overlay;
         // overflow-y: visible;
         border-radius: 14px;
         background-color: var(--bgc-1);
         padding: 4px 8px;
         width: fit-content;
         .GroupTitle {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 700;
            // margin-bottom: 1rem;
            white-space: nowrap;
         }
         .typeGroup {
            display: flex;
            flex-direction: column;
            gap: 12px;
            flex-wrap: wrap;
         }
         .typeTable {
            max-width: 100%;
            overflow-x: overlay;
         }
         .titlesC {
            display: flex;
            // overflow-x: overlay;
            flex-wrap: wrap;
            gap: 1rem;
         }
      }
   }
   .botBar {
      display: flex;
      align-items: center;
      gap: 8px;
      border-left: 1px solid var(--bgc-1-o);
      background-color: var(--text-1);
      position: sticky;
      bottom: 0;
      right: 0;
      width: 100%;
      padding: 8px;
      transition: 0.4s ease all;
      max-height: 80px;
      .toolboxC {
         position: absolute;
         bottom: calc(100% + 1rem);
         right: 1rem;
         .toolbox {
            background-color: var(--scnd-1);
            max-height: 40px;
            max-width: 40px;
            padding: 5px 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            border-radius: 20px;
            opacity: 0.5;
            overflow: hidden;
            transition: 0.4s ease all;
            > button {
               border-radius: 10px;
               transition: 0.4s ease all;
               height: 30px;
               padding: 5px;
               display: flex;
               align-items: center;
               justify-content: center;
               width: 100%;
               display: flex;
               gap: 8px;
               > img {
                  filter: var(--bgc-1-filter);
                  height: 20px;
                  width: 20px;
               }
               > span {
                  color: var(--bgc-1);
                  font-size: 12px;
                  font-weight: 700;
                  display: none;
               }
               &:hover {
                  background-color: var(--bgc-1);
                  > img {
                     filter: var(--text-1-filter);
                  }
                  > span {
                     color: var(--text-1);
                  }
               }
            }
         }
         .noteCount {
            position: absolute;
            top: -0px;
            right: 0px;
            background-color: var(--main-3);
            color: var(--bgc-1);
            font-size: 12px;
            font-weight: 700;
            height: 18px;
            width: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            transition: all 0.4s ease;
            opacity: 1;
         }
         &:hover {
            .toolbox {
               opacity: 1;
               max-height: 50vh;
               max-width: 50vw;
               > button:first-child {
                  background-color: var(--bgc-1);
                  > img {
                     filter: var(--text-1-filter);
                  }
               }
               > button:not(:first-child) {
                  justify-content: flex-start;
                  > span {
                     display: inline;
                  }
               }
            }
            .noteCount {
               opacity: 0;
            }
         }
      }
      .botBarL {
         flex-grow: 1;
         display: flex;
         gap: 8px;
         align-items: center;
         overflow-x: auto;
         z-index: 1;
      }
      .botBarR {
         flex-shrink: 0;
         > button {
            height: 40px;
            width: 40px;
            transition: 0.4s ease all;
            border-radius: 10px;
            > img {
               transition: 0.4s ease all;
               filter: var(--bgc-1-filter);
               transform: rotate(90deg);
               height: 20px;
               width: 20px;
            }
            &:hover {
               background-color: var(--bgc-1);
               > img {
                  filter: var(--text-1-filter);
               }
            }
         }
      }
   }
   .botBarExpand {
      max-height: 50vh;
      .botBarL {
         flex-wrap: wrap;
      }
      .botBarR {
         > button {
            > img {
               transform: rotate(-90deg);
            }
         }
      }
   }
}
@media screen and (max-width: 1024px) {
   .contentC {
      .overviewC {
         .overviewBodyC {
            .overviewBodyR {
               > .Groups {
                  overflow-x: hidden !important;
               }
               table {
                  > thead,
                  tbody {
                     > tr {
                        > td:nth-child(n + 3):nth-child(-n + 5),
                        th:nth-child(n + 3):nth-child(-n + 5) {
                           display: none;
                        }
                     }
                  }
               }
            }
         }
      }
   }
}
