.contentC {
   padding: 0 0 30rem 1rem;
   .mapC {
      height: 40rem;
      width: 100%;
      display: flex;
      .map {
         flex-grow: 1;
      }
   }
   .externalC,
   .filtersC {
      width: 40rem;
   }
   .filtersC {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 1rem;
      .filters {
         display: flex;
         gap: 1rem;
         > * {
            width: 50%;
         }
      }
   }
   .checkboxesC {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      > * {
         border: 1px solid var(--text-1);
         width: 20rem;
         padding: 0.4rem 0.8rem;
      }
   }
}
