.navSubItem {
   position: relative;
   width: 50%;

   > a {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      padding: 8px 0;
      gap: 6px;
      > img {
         height: 26px;
         width: 26px;
         filter: var(--text-1-filter);
      }
      > span {
         font-weight: 700;
         font-size: 10px;
         white-space: nowrap;
      }
      &:hover {
         background-color: var(--scnd-1);
         > img {
            filter: var(--bgc-1-filter);
         }
         > span {
            color: var(--bgc-1);
         }
      }
   }
   // &:not(:last-child)::before {
   //    content: "";
   //    display: block;
   //    height: 1px;
   //    width: 100%;
   //    position: absolute;
   //    bottom: -3px;
   //    left: 0;
   //    border-top: 1px solid var(--text-1-o-10);
   // }
}
.navItem {
   position: relative;
   > a,
   div {
      height: 50px;
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      display: flex;
      gap: 24px;
      > img {
         width: 20px;
         height: 20px;
         filter: invert(100%);
         transition: all 1s ease;
      }
      > span {
         display: none;
         color: var(--bgc-1);
         font-weight: 700;
      }
   }

   > ul {
      position: absolute;
      left: calc(100% + 16px);
      top: 0;
      display: flex;
      flex-wrap: wrap;
      height: fit-content;
      width: calc(169px * 2);
      background-color: var(--bgc-1);
      border-radius: 10px;
      box-shadow: 0px 20px 34px 0px rgba(59, 60, 68, 0.07);
   }
   &:hover > a,
   &:hover > div,
   .active {
      background-color: var(--bgc-1);
      > img {
         filter: var(--text-1-filter);
      }
      > span {
         color: var(--text-1);
      }
   }
}
.bodyC {
   display: flex;
   position: relative;
   > nav {
      user-select: none;
      height: 100vh;
      background-color: var(--scnd-1);
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      transition: all 0.4s ease;
      width: 64px;
      max-width: 64px;
      z-index: 9;
      .top {
         flex-grow: 1;
         padding: 1rem 8px 0 8px;
         width: 100%;
         display: flex;
         flex-direction: column;
         justify-content: space-between;
         .toptop {
            .headerC {
               display: flex;
               margin-bottom: 2rem;
               align-items: center;
               gap: 20px;
               width: 100%;
               justify-content: center;

               > a {
                  display: none;
                  .logo {
                     height: 30px;
                     transform: scale(2);
                     transform-origin: 0 50%;
                  }
               }
               .burger {
                  height: 30px;
                  width: 30px;
                  filter: invert(100%);
                  cursor: pointer;
               }
            }
            > ul {
               display: flex;
               flex-direction: column;
               gap: 8px;
            }
         }
         .topbot {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column-reverse;
            > div,
            a {
               cursor: pointer;
               height: 50px;
               width: 50px;
               display: flex;
               align-items: center;
               justify-content: center;
               border-radius: 10px;
               margin-bottom: 8px;
               transition: 0.4s all ease;
               > img {
                  height: 20px;
                  width: 20px;
                  filter: var(--bgc-1-filter);
               }
               &:hover {
                  background-color: var(--bgc-1);
                  > img {
                     filter: var(--text-1-filter);
                     // filter: none;
                  }
               }
               &:last-child {
                  position: relative;
                  > div:last-child {
                     position: absolute;
                     top: -8px;
                     right: -8px;
                  }
                  > p:last-child {
                     position: absolute;
                     font-size: 10px;
                     font-weight: 700;
                     color: var(--text-1);
                     top: 50%;
                     left: 50%;
                     transform: translate(-50%, -50%);
                  }
                  &:hover {
                     > p:last-child {
                        color: var(--bgc-1);
                     }
                  }
               }
            }
         }
      }
      .bot {
         width: 100%;
         padding: 14px 6px;
         background-color: var(--text-1);
         .userC {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            .picture {
               border-radius: 10px;
               height: 2rem;
               width: 2rem;
            }
            .user {
               display: none;
               > p {
                  color: var(--bgc-1);
                  &:first-child {
                     font-weight: bold;
                  }
                  &:last-child {
                     font-size: 12px;
                     text-transform: uppercase;
                  }
               }
            }
            .logout {
               cursor: pointer;
               display: none;
               height: 1rem;
               filter: var(--bgc-1-filter);
            }
         }
      }
   }
   > *:last-child {
      flex-grow: 1;
      height: 100vh;
      overflow-y: auto;
   }

   .expand {
      width: 250px;
      max-width: 250px;
      .top {
         .toptop {
            .headerC {
               justify-content: space-between;
               > a {
                  display: block;
               }
            }
            > ul {
               > li {
                  > a,
                  div {
                     width: 100%;
                     justify-content: flex-start;
                     padding: 0 25px;
                     > img {
                     }
                     > span {
                        display: block;
                     }
                  }
               }
            }
         }
         .topbot {
            flex-direction: row;
         }
      }
      .bot {
         .userC {
            justify-content: space-between;
            padding: 0 8px;
            .user,
            .logout {
               display: block;
            }
            .user {
               flex-grow: 1;
            }
         }
      }
   }
   .hidden {
      transform: translateX(-100%);
      position: absolute;
   }
   .hoverShowBtn {
      z-index: 10;
      cursor: pointer;
      position: fixed;
      bottom: calc(60px + 8px);
      left: 8px;
      height: 50px;
      width: 50px;
      border: 1px solid black;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      opacity: 0.5;
      transition: 0.4s ease all;
      filter: var(--text-1-filter);
      > img {
         transform: rotate(180deg);
         height: 20px;
         width: 20px;
      }
      &:hover {
         opacity: 1;
      }
   }
}
