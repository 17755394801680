.contentC {
   padding-bottom: 10rem;
   .postDataC{
      padding: 1rem;
      background-color: var(--bgc-1);
      border-radius: 20px;
      max-width: min(100vw, 600px);
      max-height: min(100vh, 400px);
      overflow-x: auto;
      .postDataTitle {
         display: flex;
         align-items: center;
         justify-content: space-between;
         margin-bottom: 1rem;
      }
      .postDataInfoC{

      }
   }
   .sd {
      padding: 1rem;
      .dHeader {
         display: flex;
         gap: 1rem;
         align-items: center;
         font-size: 20px;
      }
      .tableControls {
         width: fit-content;
         display: flex;
         align-items: center;
         gap: 8px;
         padding: 10px;
      }
      .brokerInfoC {
         margin-top: 8px;
         display: flex;
         font-size: 14px;
         gap: 8px;
      }
      .tableC {
         padding: 2px;
         max-width: 100%;
         overflow-x: auto;
      }
   }

   .Groups {
      background-color: var(--body-bgc-1);
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      margin-bottom: 1rem;
      justify-content: center;
      width: fit-content;
      max-width: 100%;
      // overflow-x: scroll;
      // overflow-y: visible;
      .Group {
         max-width: 100%;
         // overflow-x: overlay;
         // overflow-y: visible;
         border-radius: 14px;
         background-color: var(--bgc-1);
         padding: 16px 20px;
         width: fit-content;
         .GroupTitle {
            display: flex;
            align-items: center;
            font-size: 18px;
            margin-bottom: 1rem;
            white-space: nowrap;
         }
         .typeGroup {
            display: flex;
            flex-direction: column;
            gap: 12px;
         }
         .typeTable {
            max-width: 100%;
            overflow-x: overlay;
         }
         .titlesC {
            display: flex;
            // overflow-x: overlay;
            flex-wrap: wrap;
            gap: 1rem;
         }
      }
   }
}
