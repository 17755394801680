.contentC {
   padding: 30px;
   border-radius: 20px;
   background-color: var(--bgc-1);
   width: 80vw;
   height: 80vh;
   overflow-y: overlay;
   display: flex;
   flex-direction: column;
   gap: 1rem;
   .header {
      display: flex;
      justify-content: space-between;
   }
   .note {
      padding: 10px 20px;
      border-radius: 10px;
      background-color: var(--bgc-2);
   }
   > form {
      width: 30rem;
      margin: auto;
      display: flex;
      flex-direction: column;
      gap: 14px;
      .row {
         display: flex;
         gap: 14px;
         > * {
            flex: 1 1 0px;
         }
      }
   }
   .controlsC {
      flex-grow: 1;
      display: flex;
      gap: 1rem;
      align-items: flex-end;
      justify-content: flex-end;
   }
}
@media screen and (max-width: 769px) {
   .contentC {
      padding: 2rem;
      width: 90vw;
      .note {
         font-size: 14px;
      }
      > form {
         width: 100%;
      }
   }
}
