.contentC {
   .headerC {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      background-color: var(--background-1);
   }
   .bodyC {
      padding: 1rem;
      .buwsC {
         .buwsH {
            margin-bottom: 1rem;
         }
         .buws {
            display: flex;
            gap: 1rem;
            flex-wrap: wrap;
            justify-content: center;
         }
      }
   }
}

.buw {
   display: block;
   background-color: var(--background-1);
   border-radius: 20px;
   width: 282px;
   .p1 {
      padding: 14px;
      > b {
         font-size: 22px;
      }
      > p {
         font-size: 16px;
         color: var(--text-0-o-50);
      }
   }
   .p2 {
      display: flex;
      --border: 1px solid var(--text-0-o-10);
      > div {
         padding: 6px 0;
         flex-grow: 1;
         flex-basis: 0;
         display: flex;
         flex-direction: column;
         align-items: center;
         border-top: var(--border);
         border-bottom: var(--border);
         > p {
            font-size: 12px;
            color: var(--text-0-o-50);
         }
         > b {
            font-size: 14px;
         }
         &:not(:last-child) {
            border-right: var(--border);
         }
      }
   }
   .p3{
      padding: 1rem;
      >div {
         >span {
            font-size: 14px;
            color: var(--text-0-o-50);
         }
         > b {
            font-size: 14px;
         }
      }
   }
}
