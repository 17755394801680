.contentC {
   padding: 2rem;
   border-radius: 30px;
   background-color: var(--bgc-1);
   display: flex;
   flex-direction: column;
   gap: 1rem;
   .title {
      font-weight: 700;
      text-transform: uppercase;
   }
   .content {
      display: flex;
      .form {
         padding: 1rem;
         min-width: 20rem;
         display: flex;
         .settings {
            padding-right: 1rem;
            border-right: 1px solid var(--text-1-o);
         }
         .fieldsC {
            padding-left: 1rem;
            max-height: 20rem;
            min-width: 30rem;
            overflow-y: auto;
            > :not(:last-child) {
               margin-bottom: 6px;
            }
            .fieldC {
               padding: 0.4rem 0.2rem;
               border: 1px solid var(--text-1-o);
               .tableInfoC {
                  display: flex;
                  justify-content: space-between;
                  .row,
                  .col {
                     display: flex;
                     align-items: center;
                     img {
                        height: 16px;
                     }
                  }
               }
               .field {
                  display: flex;
                  justify-content: space-between;
                  .nameC {
                     font-weight: 700;
                     overflow: hidden;
                     white-space: nowrap;
                     text-overflow: ellipsis;
                     display: flex;
                     align-items: center;
                     gap: 1rem;
                  }
                  .icons {
                     display: flex;
                     justify-content: flex-end;
                     gap: 0.6rem;
                     img {
                        height: 16px;
                        width: 16px;
                        cursor: pointer;
                        &:hover {
                           outline: 1px solid black;
                        }
                     }
                  }
               }
            }
         }
      }
      .finderC {
         padding: 1rem;
         height: 20rem;
         width: 24rem;
         border-left: 1px solid var(--text-1-o);
         .fieldsC {
            overflow-x: hidden;
            overflow-y: auto;
            height: 100%;
            .field {
               cursor: pointer;
               &:hover {
                  background-color: var(--text-1-o);
               }
            }
         }
      }
   }
   .controlsC {
      display: flex;
      gap: 1rem;
      width: fit-content;
   }
}
